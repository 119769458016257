import React, { useState } from "react";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import withAuth from "@ttl/shared-react-library/src/auth/WithAuth";
import FormGroup from "@trimbleinc/modus-react-bootstrap/esm/FormGroup";
import FormCheckbox from "../../../atoms/FormCheckbox/FormCheckbox";
import { IMapMenuItems } from "../MapMenuContainer/MapMenuContainer";

export interface IMapMenu {
  mapMenuItems: IMapMenuItems[];
  handleMapMenuCheckBoxClick?: (key: string, flag: boolean) => void;
}

const MapMenu = (props: IMapMenu) => {
  const { mapMenuItems, handleMapMenuCheckBoxClick } = props;

  return (
    <>
      <FormGroup className="m-0">
        {mapMenuItems?.map((mapMenuItem: IMapMenuItems) => {
          return (
            <div className="option-menu-container" key={mapMenuItem.key}>
              <FormCheckbox
                id={mapMenuItem.key}
                label={i18nInstance.t(mapMenuItem.label)}
                isChecked={mapMenuItem.isChecked}
                onChange={() => {
                  if (!mapMenuItem.isDisabled) {
                    handleMapMenuCheckBoxClick?.(mapMenuItem.key, !mapMenuItem.isChecked);
                  }
                }}
                isDisabled={mapMenuItem.isDisabled}
                title={i18nInstance.t(mapMenuItem.title ?? "")}
              />
            </div>
          );
        })}
      </FormGroup>
    </>
  );
};

export default withAuth(MapMenu);
