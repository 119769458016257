import React, { useState } from "react";
import Switch from "../../../global/molecules/Switch/Switch";
import "./TracesTaskView.scss";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import { sendMonitoringLogs } from "../../../../common/utils";

export interface ITracesTaskView {
  handleTaskData: (flag: boolean) => void;
  showTaskView?: boolean;
}
const TracesTaskView = (props: ITracesTaskView) => {
  const [showTaskView, setShowTaskView] = useState<boolean>(
    props?.showTaskView ? props.showTaskView : false,
  );
  const handleShowTasksToggle = (flag: boolean) => {
    setShowTaskView(flag);
    props.handleTaskData(flag);
    sendMonitoringLogs(flag ? "HISTORY_TASK_TOGGLE_SHOW" : "HISTORY_TASK_TOGGLE_HIDE");
  };

  return (
    <div className="trace-task-switch">
      <Switch
        label={i18nInstance.t("TTM.followup.traces.showTasks")}
        isEnabled={true}
        isChecked={showTaskView}
        onChange={handleShowTasksToggle}
        title={i18nInstance.t("TTM.followup.traces.showTasks")}
        defaultChecked={showTaskView}
      />
    </div>
  );
};
export default TracesTaskView;
