import React, { useEffect, useRef, useState } from "react";
import "./TracesContainer.scss";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useEventSource } from "../../../../../hooks/useEventSource/useEventSource";
import TraceService from "../../../../../services/Trace.service";
import { ITimelineSegmentData, ITrace } from "../../../../../models/TraceModel";
import {
  getEndTimeFromDate,
  getStartTimeFromDate,
  sendMonitoringLogs,
} from "../../../../../common/utils";
import {
  DATE_DISPLAY_FORMAT,
  HISTORY_VIEWS,
  PATH,
  TOAST_STYLE,
} from "../../../../../common/constants";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import moment from "moment";
import ToastWrapper, {
  IToast,
  defaultToastObj,
} from "../../../molecules/ToastWrapper/ToastWrapper";
import { useTraceStore } from "../../../../../contexts/traces.context";
import { observer } from "mobx-react-lite";
import { getIdsWithinTimeRange } from "../../../../../common/utils";
import isEqual from "lodash/isEqual";

const TracesContainer = () => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const traceStore = useTraceStore();
  const traceService = new TraceService();
  const [selectedTrace, setSelectedTrace] = useState<ITrace | null>(null);
  const [filteredTraceIds, setFilteredTraceIds] = useState<string[]>([]);
  const [highlightedTraceIds, setHighlightedTraceIds] = useState<string[]>([]);
  const [sourceURL, setSourceURL] = useState<string>("");
  const [terminalId, setTerminalId] = useState<string>("");
  const [queryDate, setQueryDate] = useState<string>("");
  const [resetTraces, setResetTraces] = useState<boolean>(false);
  const [toastObj, setToastObj] = useState<IToast>(defaultToastObj);
  const [tracesList, setTracesList] = useState<ITrace[]>([]);
  const [selectedTask, setSelectedTask] = useState<ITimelineSegmentData | null>(null);
  const tracesListRef = useRef<ITrace[]>([]);
  const filteredTracesRef = useRef<ITrace[]>([]);

  const [source, data, isCompleted, error] = useEventSource({
    src: { url: sourceURL },
  });

  useEffect(() => {
    if (data) {
      tracesListRef.current = [...tracesListRef.current, data];
      setTracesList([...tracesList, data]);
    }
  }, [data]);

  const onSelectedTrace = (trace: ITrace) => {
    setSelectedTrace(trace);
  };
  const onSelectedTask = (task: ITimelineSegmentData) => {
    setSelectedTask(task);
  };
  const onHighlightedTraces = (traceIds: string[]) => {
    setHighlightedTraceIds(traceIds);
  };

  const onFilterTraces = (traceIds: string[]) => {
    if (traceIds.length > 0) {
      const filteredTraces = tracesListRef.current.filter((trace) =>
        traceIds.includes(trace.id.toString()),
      );
      setTracesList(filteredTraces);
      filteredTracesRef.current = filteredTraces;
    } else if (traceIds.length == 0 && traceStore?.selectedView != HISTORY_VIEWS.DEFAULT) {
      setTracesList([]);
    } else {
      setTracesList(tracesListRef.current);
    }
  };

  /**
   * Function to get the traceIds within the time range based on the click or zoom event on the timeline.
   * @param startTime - Start time of the timeline when a user clicks or zooms in.
   * @param endTime - End time of the timeline of the timeline when a user clicks or zooms in.
   * @param data - Data from the timeline when user clicks on a particular segment.
   */
  const onTimelineChange = (startTime: number, endTime: number, data?: any) => {
    if (tracesListRef.current.length > 0) {
      const traceIdsWithinRange = getIdsWithinTimeRange(tracesListRef.current, startTime, endTime);
      if (data) {
        setHighlightedTraceIds(traceIdsWithinRange);
        sendMonitoringLogs("HISTORY_TIMELINE_SEGMENT_CLICK");
      } else {
        const filteredTraces = (
          traceStore?.selectedView !== HISTORY_VIEWS.DEFAULT ? filteredTracesRef : tracesListRef
        ).current.filter((trace) => traceIdsWithinRange.includes(trace.id.toString()));
        setTracesList(filteredTraces);
        setFilteredTraceIds(traceIdsWithinRange);
        sendMonitoringLogs("HISTORY_TIMELINE_ZOOM");
        setHighlightedTraceIds([]);
      }
    }
  };

  const shouldUpdateTraceFilter = () => {
    const { selectedDate, terminalId } = traceStore?.traceFilter || {};
    return (
      !isEqual(selectedDate, moment(params.date, DATE_DISPLAY_FORMAT, true)) ||
      !isEqual(terminalId, params.unitId)
    );
  };

  const shouldResetTraceDetails = (): boolean => {
    const traceTypes = traceStore?.traceFilter?.traceTypes || [];
    return params?.unitId !== terminalId || params?.date !== queryDate || traceTypes?.length >= 0;
  };

  const onCleanUp = () => {
    tracesListRef.current = [];
    setResetTraces(false);
    setTracesList([]);
    setSourceURL("");
    setSelectedTrace(null);
    setHighlightedTraceIds([]);
    setFilteredTraceIds([]);
    setSelectedTask(null);
  };

  useEffect(() => {
    error &&
      setToastObj({
        showToast: true,
        message: i18nInstance.t("TTM.followup.generic.error"),
        style: TOAST_STYLE.ERROR,
      });
  }, [error]);

  /**
   * UseEffect to abort/close the stream when user changes the unit while existing request in progress.
   */
  useEffect(() => {
    if ((params?.unitId !== terminalId || params?.date !== queryDate) && !isCompleted) {
      source?.close();
    }
  }, [params?.unitId, params?.date, isCompleted]);

  /**
   * UseEffect to define start and end time from the params.
   * If date is invalid, user will be navigated to /history path.
   * If unitId or date is changed, reset will be set to true.
   */
  useEffect(() => {
    try {
      if (
        params?.unitId &&
        params?.date &&
        moment(params?.date, DATE_DISPLAY_FORMAT, true)?.isValid() &&
        moment(params?.date, DATE_DISPLAY_FORMAT, true)?.isSameOrBefore(moment())
      ) {
        const traceTypes = traceStore?.traceFilter?.traceTypes;
        setTerminalId(params?.unitId);
        setQueryDate(params?.date);
        if (shouldResetTraceDetails()) {
          setResetTraces(true);
          setSelectedTrace(null);
          traceStore?.setTraceDetails(null);
        }
        if (shouldUpdateTraceFilter()) {
          traceStore?.setTraceFilter?.({
            ...traceStore.traceFilter,
            selectedDate: moment(params.date, DATE_DISPLAY_FORMAT, true),
            terminalId: params.unitId,
          });
        }
        const startTime = getStartTimeFromDate(params.date);
        const endTime = getEndTimeFromDate(params.date);
        setSourceURL(
          traceService.getTraces(
            params.unitId,
            startTime,
            endTime,
            traceTypes?.map((i) => i.value)?.join(),
          ),
        );
        setToastObj({
          showToast: true,
          message: i18nInstance.t("TTM.followup.traces.loading"),
          style: TOAST_STYLE.PRIMARY,
          delay: 1250,
        });
      } else {
        navigate(PATH.TRACES);
      }
    } catch (error) {
      console.log("tracesContainer ~ error:", error);
    }
    return () => {
      onCleanUp();
    };
  }, [location?.pathname, traceStore?.traceFilter?.traceTypes]);

  return (
    <>
      <Outlet
        context={{
          error,
          queryDate,
          tracesList,
          terminalId,
          trace: data,
          resetTraces,
          selectedTrace,
          filteredTraceIds,
          highlightedTraceIds,
          isDataLoaded: isCompleted,
          traceFilter: traceStore?.traceFilter,
          tracesListRef: tracesListRef.current,
          selectedTask,
          handleResetTraces: onCleanUp,
          handleSelectedTrace: onSelectedTrace,
          handleOnTimelineChange: onTimelineChange,
          handleFilterTraces: onFilterTraces,
          handleHighlightedTraces: onHighlightedTraces,
          handleSelectedTask: onSelectedTask,
        }}
      />
      <div className="traces-toast">
        <ToastWrapper
          toastProps={toastObj}
          onClose={() => setToastObj({ ...toastObj, showToast: false })}
        />
      </div>
    </>
  );
};

export default observer(TracesContainer);
