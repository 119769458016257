import React, { useState, useRef, useEffect } from "react";
import "./MapLayerStyleContainer.scss";
import MapStyle from "../MapStyle/MapStyle";
import { MapService } from "../Services/MapService";
import TrimbleMaps, { Common } from "@trimblemaps/trimblemaps-js";
import MapLayer, { MAPLAYERS } from "../MapLayer/MapLayer";
import Card from "@trimbleinc/modus-react-bootstrap/esm/Card";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import useOnOutsideClick from "@utilityjs/use-on-outside-click";
import withAuth from "@ttl/shared-react-library/src/auth/WithAuth";
import { ReactComponent as LayerStyleIcon } from "../../../../assets/images/map_layer.svg";
import { sendMonitoringLogs } from "../../../../common/utils";
import { usePersistedMapStyleAndLayer } from "../../../../hooks/usePersistedMapStyleAndLayer/usePersistedMapStyleAndLayer";

export interface IMapLayerStyleContainer {
  mapService?: MapService;
}

export interface IMapLayerStyleOption {
  img: string;
  key: string;
  type: string;
  options?: {
    satelliteProvider?: (typeof Common.SatelliteProvider)[keyof typeof Common.SatelliteProvider];
  };
}

const MapLayerStyleContainer = (props: IMapLayerStyleContainer) => {
  const { mapService } = props;
  const ref = useRef<HTMLDivElement>(null);
  const [mapZoom, setMapZoom] = useState<number>(3);
  const [showOptions, setShowOptions] = useState(false);

  const [truckRestriction] = useState(new TrimbleMaps.TruckRestriction());
  const [truckRestrictionCtrl] = useState(new TrimbleMaps.TruckRestrictionClickControl());

  const [trafficIncident] = useState(new TrimbleMaps.TrafficIncident());
  const [trafficIncidentCtrl] = useState(new TrimbleMaps.TrafficIncidentClickControl());

  const [mapStyleParams, mapLayerParams, setMapStyleParams, setMapLayerParams] =
    usePersistedMapStyleAndLayer({});

  useOnOutsideClick(ref, () => {
    setShowOptions(false);
  });

  useEffect(() => {
    if (mapService) {
      truckRestriction.addTo(mapService.getMap());
      trafficIncident.addTo(mapService.getMap());
      mapService.getMap().addControl(truckRestrictionCtrl);
      mapService.getMap().addControl(trafficIncidentCtrl);
      if (!mapLayerParams.includes(MAPLAYERS.TRUCK)) {
        truckRestriction.setVisibility(false);
      }
      if (!mapLayerParams.includes(MAPLAYERS.TRAFFIC)) {
        trafficIncident.setVisibility(false);
      }
    }
  }, [mapService]);

  mapService?.getMap()?.on("zoom", () => {
    setMapZoom(mapService?.getZoom());
  });

  const handleMapStyleChange = (selectedOption: IMapLayerStyleOption) => {
    try {
      setMapStyleParams && setMapStyleParams(selectedOption.type);
      if (selectedOption && mapService) {
        mapService.setStyle(selectedOption.type, selectedOption?.options);
      }
      sendMonitoringLogs("MAP_STYLE_CHANGE", selectedOption.type);
    } catch (e) {
      console.log("Handle map type change", e);
    }
  };

  const handleMapLayerChange = (selectedOption: IMapLayerStyleOption) => {
    try {
      const layerArr = mapLayerParams;
      const isAlreadySelected = layerArr.includes(selectedOption.type);
      isAlreadySelected
        ? setMapLayerParams(layerArr.filter((s) => s !== selectedOption.type))
        : setMapLayerParams([...mapLayerParams, selectedOption.type]);
      if (mapService && selectedOption) {
        if (selectedOption.type === MAPLAYERS.TRAFFIC) {
          mapService.getMap().toggleTrafficVisibility();
          trafficIncident.toggleVisibility();
        }
        if (selectedOption.type === MAPLAYERS.TRUCK) {
          mapService.toggleTruckRestriction(truckRestriction);
        }
      }
      !isAlreadySelected && sendMonitoringLogs("MAP_LAYER_CHANGE", selectedOption.type);
    } catch (e) {
      console.log("Handle map layer change", e);
    }
  };

  return (
    <div ref={ref}>
      <LayerStyleIcon
        title={i18nInstance.t("TTM.followup.map.styleLayerIcon")}
        className="map-style-layer-icon"
        onClick={() => {
          setShowOptions(!showOptions);
          sendMonitoringLogs("VEHICLES_MAP_LAYER_OPEN");
        }}
      />
      {showOptions && (
        <Card border="dark" className="shadow map-style-layer-container">
          <MapStyle
            selectedMapStyle={
              mapStyleParams ? mapStyleParams : TrimbleMaps.Common.Style.TRANSPORTATION
            }
            handleMapStyleChange={handleMapStyleChange}
          />
          <span className="map-style-layer-separator" />
          <MapLayer
            zoomLevel={mapZoom}
            selectedMapLayers={mapLayerParams}
            handleMapLayerChange={handleMapLayerChange}
          />
        </Card>
      )}
    </div>
  );
};

export default withAuth(MapLayerStyleContainer);
