import React, { useEffect, useState } from "react";
import Address from "../../../domain/Vehicle/molecules/Address/Address";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import Preloader from "@ttl/shared-react-library/src/components/Preloader/Preloader";
import refreshIcon from "../../../../assets/images/refresh.svg";
import { useTraceDetails } from "../../../../hooks/domain/useTraceDetails/useTraceDetails";
import { ALL_DATE_TIME_FORMATS, HOURS_MINUTES_FORMAT } from "../../../../common/constants";
import { ITrace } from "../../../../models/TraceModel";
import DateTime from "../../../molecules/DateTime/DateTime";
import useMobileDetect from "../../../../hooks/useMobileDetect/useMobileDetect";
import close from "../../../../assets/images/close.svg";
import moment from "moment";
import "./CustomTraceView.scss";
import { parseDurationToMilliseconds } from "../../../../common/utils";

export interface ICustomTraceViewProps {
  trace: ITrace;
  terminalId?: string;
  traceName?: string;
  image?: string;
  onClose?: () => void;
}

const CustomTraceView = (props: ICustomTraceViewProps) => {
  const isMobile = useMobileDetect();
  const { fetchTraceDetails, traceDetails, loading, error } = useTraceDetails({
    terminalId: props?.terminalId,
    traceId: props?.trace.id,
  });

  const [startTime, setStartTime] = useState<number | null>(null);
  const [duration, setDuration] = useState<string | null>(null);
  const [endTime, setEndTime] = useState<number | null>(null);

  useEffect(() => {
    if (traceDetails) {
      const tracePropertiesMap = new Map(
        traceDetails?.traceProperties?.map((property) => [property.key, property]),
      );

      const startTime = tracePropertiesMap.get("AST")?.value;
      const duration = tracePropertiesMap.get("ALEN")?.value;
      const endTime = traceDetails?.timestamp;
      if (startTime) {
        setStartTime(moment(startTime, ALL_DATE_TIME_FORMATS).valueOf());
      } else if (duration && endTime) {
        const durationInMilliseconds = parseDurationToMilliseconds(duration);
        durationInMilliseconds && setStartTime(endTime - durationInMilliseconds);
      }
      if (duration) setDuration(duration.replace(/([hmsHMS])/g, "$1 ").trim());
      if (endTime) setEndTime(endTime);
    }
  }, [traceDetails]);

  return (
    <div className="traceview-info-container">
      {isMobile && props?.onClose && (
        <img
          src={close}
          className="traceview-info-close-icon"
          alt={i18nInstance.t("TTM.followup.close")}
          onClick={props?.onClose}
        />
      )}
      {loading && (
        <div className="traceview-info-loader">
          <Preloader />
        </div>
      )}
      {!loading && !error && (
        <>
          <div className="traceview-info-title truncate-text">
            {props?.image && <img src={props?.image} className="traceview-info-icon" />}
            {
              <div className="traceview-info-content truncate-text mr-2" title={props?.traceName}>
                {props?.traceName}
              </div>
            }
            {duration && (
              <div className="traceview-info-duration" title={duration}>
                {duration}
              </div>
            )}
          </div>
          <div className="traceview-info-details">
            {startTime && (
              <div>
                <span className="font-weight-bold">{`${i18nInstance.t(
                  "TTM.followup.duration.startTime",
                )}: `}</span>
                <DateTime dateTime={startTime} displayFormat={HOURS_MINUTES_FORMAT} />
              </div>
            )}
            {endTime && (
              <div>
                <span className="font-weight-bold">{`${i18nInstance.t(
                  "TTM.followup.duration.endTime",
                )}: `}</span>
                <DateTime dateTime={endTime} displayFormat={HOURS_MINUTES_FORMAT} />
              </div>
            )}
            {traceDetails?.address && (
              <div className="traceview-info-address">
                <span className="font-weight-bold mr-1">{`${i18nInstance.t(
                  "TTM.followup.search.location",
                )}: `}</span>
                <Address data={traceDetails?.address} lineClamp={3} />
              </div>
            )}
            {!traceDetails && (
              <div className="traceview-info-nodata">
                <span>{i18nInstance.t("TTM.followup.error.noDataAvailable")}</span>
                <div className="traceview-info-retry">
                  <span>{i18nInstance.t("TTM.followup.error.retry")}</span>
                  <img
                    className="refresh-icon"
                    title={i18nInstance.t("TTM.followup.refreshData")}
                    src={refreshIcon}
                    onClick={fetchTraceDetails}
                  />
                </div>
              </div>
            )}
          </div>
        </>
      )}
      {error && !loading && (
        <div className="traceview-info-error">
          <span>{i18nInstance.t("TTM.followup.error.dataLoad")}</span>
          <img
            className="refresh-icon"
            title={i18nInstance.t("TTM.followup.refreshData")}
            src={refreshIcon}
            onClick={fetchTraceDetails}
          />
        </div>
      )}
    </div>
  );
};

export default CustomTraceView;
