import Unknown from "../images/trace/activity_categories/unknown.svg";
import Drive from "../images/trace/activity_categories/drive.svg";
import Rest from "../images/trace/activity_categories/rest.svg";
import Load from "../images/trace/activity_categories/load.svg";
import Unload from "../images/trace/activity_categories/unload.svg";
import Wait from "../images/trace/activity_categories/wait.svg";
import Breakdown from "../images/trace/activity_categories/breakdown.svg";
import Accident from "../images/trace/activity_categories/accident.svg";
import Customs from "../images/trace/activity_categories/customs.svg";
import DocumentIcon from "../images/trace/activity_categories/document.svg";
import Fuel from "../images/trace/activity_categories/fuel.svg";
import Login from "../images/trace/activity_categories/login.svg";
import Logout from "../images/trace/activity_categories/logout.svg";
import Rent from "../images/trace/activity_categories/rent.svg";
import RoadsideCheck from "../images/trace/activity_categories/roadside_check.svg";
import Tether from "../images/trace/activity_categories/tether.svg";
import Untether from "../images/trace/activity_categories/untether.svg";
import VehicleCheck from "../images/trace/activity_categories/vehicle_check.svg";
import Wash from "../images/trace/activity_categories/wash.svg";
import Weight from "../images/trace/activity_categories/weight.svg";
import Workshop from "../images/trace/activity_categories/workshop.svg";
import Traffic from "../images/trace/activity_categories/traffic.svg";
import Trainferry from "../images/trace/activity_categories/train_ferry.svg";
import Training from "../images/trace/activity_categories/training.svg";
import Empty from "../images/trace/activity_categories/empty.svg";
import Other from "../images/trace/activity_categories/other.svg";
import BroderCrossing from "../images/trace/activity_categories/border_crossing.svg";
import Working from "../images/trace/driving_times/working.svg";
import Resting from "../images/trace/driving_times/resting.svg";
import Waiting from "../images/trace/driving_times/waiting.svg";
import Violation from "../images/trace/driving_times/violation.svg";

const CustomIcons = [
  {
    src: Accident,
    name: "accident",
  },
  {
    src: BroderCrossing,
    name: "border_crossing",
  },
  {
    src: Breakdown,
    name: "breakdown",
  },
  {
    src: Customs,
    name: "customs",
  },
  {
    src: DocumentIcon,
    name: "document",
  },
  {
    src: Drive,
    name: "drive",
  },
  {
    src: Empty,
    name: "empty",
  },
  {
    src: Fuel,
    name: "fuel",
  },
  {
    src: Load,
    name: "load",
  },
  {
    src: Login,
    name: "login",
  },
  {
    src: Logout,
    name: "logout",
  },
  {
    src: Rent,
    name: "rent",
  },
  {
    src: Rest,
    name: "rest",
  },
  {
    src: RoadsideCheck,
    name: "roadside_check",
  },
  {
    src: Tether,
    name: "tether",
  },
  {
    src: Trainferry,
    name: "trainferry",
  },
  {
    src: Training,
    name: "training",
  },
  {
    src: Traffic,
    name: "traffic",
  },
  {
    src: Unknown,
    name: "unknown",
  },
  {
    src: Unload,
    name: "unload",
  },

  {
    src: Untether,
    name: "untether",
  },
  {
    src: VehicleCheck,
    name: "vehicle_check",
  },
  {
    src: Wait,
    name: "wait",
  },
  {
    src: Wash,
    name: "wash",
  },
  {
    src: Weight,
    name: "weight",
  },
  {
    src: Workshop,
    name: "workshop",
  },
  {
    src: Other,
    name: "other",
  },
  {
    src: Working,
    name: "working",
  },
  {
    src: Resting,
    name: "resting",
  },
  {
    src: Waiting,
    name: "waiting",
  },
  {
    src: Drive,
    name: "driving",
  },
  {
    src: Violation,
    name: "invalid_driver_card",
  },
  {
    src: Violation,
    name: "driving_without_drivercard",
  },
  {
    src: Violation,
    name: "driving_without_driver",
  },
  {
    src: Violation,
    name: "driving_times_driving_violation_event",
  },
  {
    src: Violation,
    name: "driving_times_total_driving_violation_event",
  },
  {
    src: Violation,
    name: "driving_times_amplitude_violation_event",
  },
  {
    src: Violation,
    name: "remote_tacho_download_failed",
  },
];

export default CustomIcons;
