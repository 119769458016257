import { useEffect, useState } from "react";
import { useLocalStorage } from "../useLocalStorage/useLocalStorage";
import { LOCALSTORAGE_KEYS } from "../../common/constants";
import { MAPLAYERS } from "../../components/organisms/MapView/MapLayer/MapLayer";
import { MapService } from "../../components/organisms/MapView/Services/MapService";
import TrimbleMaps from "@trimblemaps/trimblemaps-js";

export interface IUsePertistedMapStyleAndLayer {
  mapService?: MapService;
}

type usePersistedMapStyleAndLayerReturn = [
  string,
  string[],
  (mapStyleValue: string) => void,
  (mapLayerValues: string[]) => void,
];

export const usePersistedMapStyleAndLayer = (
  ref: IUsePertistedMapStyleAndLayer,
): usePersistedMapStyleAndLayerReturn => {
  const [mapStyleValue, setStyleValue] = useLocalStorage<string>(
    LOCALSTORAGE_KEYS.MAP_STYLE,
    TrimbleMaps.Common.Style.TRANSPORTATION,
  );
  const [mapLayerValues, setLayerValues] = useLocalStorage<string[]>(
    LOCALSTORAGE_KEYS.MAP_LAYER,
    [],
  );
  const [truckRestriction] = useState(new TrimbleMaps.TruckRestriction());
  const [trafficIncident] = useState(new TrimbleMaps.TrafficIncident());

  const mapService = ref.mapService;

  const setMapStyle = (mapStyleValue: string) => {
    mapService?.setStyle(mapStyleValue);
    setStyleValue(mapStyleValue);
  };

  const setMapLayer = (mapLayerValues: string[]) => {
    setTimeout(() => {
      if (mapLayerValues && mapLayerValues.length > 0) {
        mapLayerValues.forEach((layer: string) => {
          if (layer === MAPLAYERS.TRAFFIC) {
            mapService?.getMap().toggleTrafficVisibility();
            if (trafficIncident) {
              trafficIncident.toggleVisibility();
            }
          }
          if (layer === MAPLAYERS.TRUCK && truckRestriction) {
            mapService?.toggleTruckRestriction(truckRestriction);
          }
        });
      }
      setLayerValues(mapLayerValues);
    }, 100);
  };

  useEffect(() => {
    if (!mapService) return;
    setMapStyle(mapStyleValue);
    setMapLayer(mapLayerValues);
  }, [mapService]);
  return [mapStyleValue, mapLayerValues, setMapStyle, setMapLayer];
};
