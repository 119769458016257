import React from "react";
import "./TraceDetails.scss";
import { ITrace } from "../../../../../models/TraceModel";
import VehicleCoordinate from "../../../Vehicle/molecules/VehicleCoordinate/VehicleCoordinate";
import VehicleDirection from "../../../Vehicle/molecules/VehicleDirection/VehicleDirection";
import VehicleMileage from "../../../Vehicle/molecules/VehicleMileage/VehicleMileage";
import { DATE_TIME_FORMAT } from "../../../../../common/constants";
import DateTime from "../../../../molecules/DateTime/DateTime";
import TraceProperties from "../../molecules/TraceProperties/TraceProperties";
import useMobileDetect from "../../../../../hooks/useMobileDetect/useMobileDetect";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import close from "../../../../../assets/images/close.svg";
import VehicleSpeed from "../../../Vehicle/molecules/VehicleSpeed/VehicleSpeed";
export interface ITraceDetailsProps {
  trace: ITrace;
  traceType?: string;
  terminalId?: string;
  activityName?: string;
  showTimestamp?: boolean;
  showTraceProperties?: boolean;
  onClose?: () => void;
}

const TraceDetails = (props: ITraceDetailsProps) => {
  const isMobile = useMobileDetect();
  const {
    trace,
    traceType,
    showTimestamp,
    activityName,
    showTraceProperties,
    terminalId,
    onClose,
  } = props;
  return (
    <>
      {isMobile && (
        <img
          src={close}
          className="trace-details-close-icon"
          alt={i18nInstance.t("TTM.followup.close")}
          onClick={onClose}
        />
      )}
      <div className="trace-details-wrapper">
        <div className="trace-details-container">
          {traceType && (
            <div className="trace-type truncate-text" title={traceType}>
              {`${traceType} ${activityName ? `- ${activityName}` : ""}`}
            </div>
          )}
          <div className="trace-details-content">
            {showTimestamp ? (
              <DateTime dateTime={trace?.timestamp} displayFormat={DATE_TIME_FORMAT} />
            ) : null}
            {trace?.heading ? <VehicleDirection heading={trace.heading} /> : null}
            {trace?.coordinate ? <VehicleCoordinate coordinates={trace.coordinate} /> : null}
            {trace?.mileage ? <VehicleMileage mileage={trace.mileage} /> : null}
            {trace?.speed && trace?.speed > 0 ? <VehicleSpeed speed={trace.speed} /> : null}
          </div>
          {showTraceProperties && <TraceProperties traceId={trace?.id} terminalId={terminalId} />}
        </div>
      </div>
    </>
  );
};

export default TraceDetails;
