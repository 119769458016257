import { action, computed, makeObservable, observable } from "mobx";
import { ITraceDetails, ITraceFilter, ITraceType } from "../../models/TraceModel";
import { IMultiSelect } from "../../components/molecules/MultiSelect/MultiSelect";
import { IListData } from "../../components/molecules/AutoComplete/AutoComplete";
import { HISTORY_VIEWS } from "../../common/constants";

export class TraceStore {
  traceTypes: ITraceType[] = [];
  traceFilter: ITraceFilter | null = null;
  vehiclesList: IListData[] = [];
  traceDetails: { [id: string]: ITraceDetails } = {};
  selectedView: string = HISTORY_VIEWS.DEFAULT;
  traceViewJsonData = new Map<string, any>();
  showError = false;
  constructor() {
    makeObservable(this, {
      vehiclesList: observable,
      traceTypes: observable,
      traceFilter: observable,
      traceDetails: observable,
      showError: observable,
      selectedView: observable,
      traceViewJsonData: observable,
      setTraceViewJSON: action,
      setSelectedView: action,
      setTraceTypes: action,
      setTraceFilter: action,
      setVehiclesList: action,
      setTraceDetails: action,
      setShowError: action,
      mappedTraces: computed,
    });
  }
  setTraceViewJSON(selectedView: string, data: any) {
    this.traceViewJsonData.set(selectedView, data);
  }
  setTraceTypes(traceTypes: ITraceType[]) {
    this.traceTypes = traceTypes;
  }
  setTraceFilter(traceFilter: ITraceFilter) {
    this.traceFilter = traceFilter;
  }
  setVehiclesList(vehiclesList: IListData[]) {
    this.vehiclesList = vehiclesList;
  }
  setTraceDetails(traceDetail: ITraceDetails | null) {
    if (traceDetail) {
      this.traceDetails = { ...this.traceDetails, [traceDetail.id]: traceDetail };
    } else {
      this.traceDetails = {};
    }
  }
  setShowError(error: boolean) {
    this.showError = error;
  }

  setSelectedView(view: string) {
    this.selectedView = view;
  }

  get mappedTraces() {
    const traces: IMultiSelect[] = [];
    this.traceTypes?.map((i: ITraceType) => {
      traces.push({ id: i.traceType, label: i.name, value: i.traceType.toString() });
    });
    return traces;
  }
}
