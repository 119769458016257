import { find, sortBy } from "lodash";
import { ACTIVITY_COLORS, COLOR_VALUES, DRIVING_TYPES_COLORS, TASKS_COLORS } from "./constants";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import { IActivityType } from "../models/VehicleModel";
import { ITimelineDetails, ITimelineSegmentData } from "../models/TraceModel";
import { ITimelineRow } from "@ttl/shared-react-library/src/packages/timeline/models/TimelineModel";
import { processSegments } from "./tracesUtils";

// templateCSS - Optional default height 20px, margin top and bottom 10px.
export const TEMPLATES = {
  block: {
    template: "block",
  },
  roundedBlock: {
    template: "rounded-block",
  },
  circleWithRectangle: {
    template: "circle-with-rectangle",
  },
};

export enum TIMELINE_CATEGORY {
  TASKS = "tasks",
  ACTIVITY = "activity",
  DRIVING_TIMES = "drivingtimes",
}

export const TIMELINE_TEMPLATES = {
  ACTIVITY: TEMPLATES.roundedBlock,
  TACHO: TEMPLATES.block,
  TASK: TEMPLATES.circleWithRectangle,
};

export const getActivityData = (data: ITimelineDetails, activityTypes: IActivityType[]) => {
  const activityTimeline = data || [];
  try {
    const initialTimeline = activityTimeline?.timelines?.[0];
    activityTimeline?.timelines?.length > 0 &&
      initialTimeline.segments &&
      initialTimeline.segments.map((item: ITimelineSegmentData) => {
        const activity = find(activityTypes, { id: item.typeId })?.icon;
        item.color =
          ACTIVITY_COLORS[activity as keyof typeof ACTIVITY_COLORS] || COLOR_VALUES.GRAY_450;
      });
    if (activityTimeline.timelines && initialTimeline) {
      initialTimeline.name = `${i18nInstance.t("TTM.followup.activity_other")}`;
      if (initialTimeline.segments && initialTimeline.segments.length > 1) {
        initialTimeline.showDetails = true;
      }
    }
    activityTimeline.displayOrder = 1;
  } catch (e) {
    console.log("Error in getActivityData: ", e);
  }
  return activityTimeline;
};

export const getDrivingTimesData = (data: ITimelineDetails) => {
  const drivingTimeline = data || [];
  try {
    drivingTimeline?.timelines &&
      drivingTimeline?.timelines.length > 0 &&
      drivingTimeline.timelines.forEach((element: ITimelineRow) => {
        element.templateCSS = { height: 25 };
        if (element.segments && element.segments.length > 1) {
          element.showDetails = true;
        }
        element.segments &&
          element.segments.map((item: ITimelineSegmentData) => {
            item.color = DRIVING_TYPES_COLORS[item.typeId as keyof typeof DRIVING_TYPES_COLORS];
            item.name = i18nInstance.t(`TTM.followup.tachoStatus.${item.name.toLowerCase()}`);
          });
      });
    drivingTimeline.displayOrder = 2;
  } catch (e) {
    console.log("Error in getDrivingTimesData: ", e);
  }
  return drivingTimeline;
};

export const addLinkingSegments = (segments: ITimelineSegmentData[]) => {
  return segments.flatMap((item: ITimelineSegmentData, i: number) => {
    const result = [{ ...item, order: `${i + 1}` }] as ITimelineSegmentData[];
    if (i < segments.length - 1) {
      result.push({
        linkingTemplate: "line",
        startTime: item.endTime,
        endTime: segments[i + 1].startTime,
        color: COLOR_VALUES.GRAY_450,
      } as unknown as ITimelineSegmentData);
    }
    return result;
  });
};

export const getTasksData = (data: ITimelineDetails) => {
  const taskTimeline = data || [];
  try {
    if (
      taskTimeline.timelines &&
      taskTimeline.timelines.length > 0 &&
      taskTimeline.timelines[0].segments &&
      taskTimeline.timelines[0].segments.length > 0
    ) {
      const timeline = taskTimeline.timelines[0];
      timeline.name = `${i18nInstance.t("TTM.followup.task_other")}`;
      timeline.templateCSS = { height: 15 };
      const segments = sortBy(timeline.segments, "startTime");
      if (segments && segments.length > 1) {
        timeline.showDetails = true;
      }
      segments.map((item: ITimelineSegmentData) => {
        item.color = TASKS_COLORS[item.status as keyof typeof TASKS_COLORS];
      });
      const { segmentsWithoutTrip, groupedSegments } = processSegments(segments);
      const updatedSegments = [
        ...segmentsWithoutTrip,
        ...Object.keys(groupedSegments).map((tripId) => {
          if (tripId) {
            const childSegments = addLinkingSegments(groupedSegments[tripId]);
            return { ...groupedSegments[tripId]?.[0], children: childSegments };
          }
          return groupedSegments[tripId];
        }),
      ];
      taskTimeline.timelines[0].segments = updatedSegments as ITimelineSegmentData[];
      taskTimeline.displayOrder = 3;
    }
  } catch (e) {
    console.log("Error in getTasksData: ", e);
  }
  return taskTimeline;
};
