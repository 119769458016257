import React, { useEffect, useRef, useState } from "react";
import { ITimelineSegmentData, ITrace } from "../../../../../models/TraceModel";
import { isCustomTraceView } from "../../../../../common/tracesUtils";
import { useTraceStore } from "../../../../../contexts/traces.context";
import { Feature, FeatureCollection } from "@trimblemaps/trimblemaps-js/geojson";
import TrimbleMaps, { EventData, LngLatBounds } from "@trimblemaps/trimblemaps-js";
import useMobileDetect from "../../../../../hooks/useMobileDetect/useMobileDetect";
import { TracesMapService } from "../../../../organisms/MapView/Services/TracesMapService/TracesMapService";
import {
  getIconImageExpression,
  getIconRotateExpression,
  getIconSizeExpression,
  getIconHighlightExpression,
  getSymbolSortHighlighttExpression,
} from "./TracesLayerHelper";
import { useAppStore } from "../../../../../contexts/app.context";
import { useParams } from "react-router-dom";
import TraceViewDetailsPopup from "../../../molecules/TraceViewDetailsPopup/TraceViewDetailsPopup";

export interface ITracesLayerProps {
  feature?: Feature;
  resetLayer?: boolean;
  map?: TrimbleMaps.Map;
  isDataLoaded?: boolean;
  selectedTrace?: ITrace;
  filteredTraceIds?: string[];
  highlightedTraceIds?: string[];
  tracesMapService?: TracesMapService;
  handleOnTraceSelect?: (selectedTrace: ITrace | null) => void;
  handleSelectedTask?: (selectedTask: ITimelineSegmentData | null) => void;
  updateFitBounds?: (bounds: LngLatBounds) => void;
}

export const TRACE_SOURCE = "trace-source";
export const TRACE_LAYER = "trace-layer";
export const TRACE_CUSTOM_ICON_UNCLUSTERED_POINTS = "trace-custom-icon-unclustered-points";

export const TRACE_MARKER = "trace_marker";
export const TRACE_MARKER_HIGHLIGHT = "trace_highlight";

const TRACE_MARKER_URL = `${process.env.PUBLIC_URL}/assets/images/vehicle_trace.png`;
const TRACE_MARKER_HIGHLIGHT_URL = `${process.env.PUBLIC_URL}/assets/images/trace_highlight.png`;

const tracesLayerImages = [
  { url: TRACE_MARKER_URL, name: TRACE_MARKER },
  { url: TRACE_MARKER_HIGHLIGHT_URL, name: TRACE_MARKER_HIGHLIGHT },
];

const TracesLayer = (props: ITracesLayerProps) => {
  const appStore = useAppStore();
  const traceStore = useTraceStore();
  const isMobile = useMobileDetect();

  const {
    map,
    feature,
    resetLayer,
    isDataLoaded,
    selectedTrace,
    filteredTraceIds,
    tracesMapService,
    highlightedTraceIds,
    updateFitBounds,
    handleSelectedTask,
    handleOnTraceSelect,
  } = props;

  const params = useParams();
  const lngLatBounds = useRef<LngLatBounds | null>(null);
  const [traceFeatures, setTraceFeatures] = useState<Feature[]>([]);

  /**************** EVENT HANDLERS **************/

  const onMouseEnter = () => {
    if (map) {
      map.getCanvas().style.cursor = "pointer";
    }
  };

  const onMouseLeave = () => {
    if (map) {
      map.getCanvas().style.cursor = "";
    }
  };

  /**************  LAYER METHODS **************/
  const addLayer = () => {
    try {
      if (!map?.getLayer(TRACE_LAYER) && map?.getSource(TRACE_SOURCE)) {
        map?.addLayer({
          id: TRACE_LAYER,
          type: "symbol",
          source: TRACE_SOURCE,
          layout: {
            "icon-size": getIconSizeExpression(),
            "icon-image": getIconImageExpression(),
            "icon-rotate": getIconRotateExpression(),
            "symbol-sort-key": 1,
            "icon-allow-overlap": true,
          },
        });
      }
    } catch (error) {
      console.log("TraceMapService ~ addLayer ~ error:", error);
    }
  };

  const addLayerIcons = () => {
    tracesMapService?.loadImagesToMap(tracesLayerImages);
  };

  const highlightTraces = (highlightIds: string[]) => {
    try {
      if (map?.getLayer(TRACE_LAYER)) {
        map?.setLayoutProperty(TRACE_LAYER, "icon-image", getIconHighlightExpression(highlightIds));
        map?.setLayoutProperty(
          TRACE_LAYER,
          "symbol-sort-key",
          getSymbolSortHighlighttExpression(highlightIds),
        );
      }
    } catch (error) {
      console.log("highlightTraces ~ error:", error);
    }
  };

  const filterTraces = (filterIds: string[]) => {
    try {
      if (map?.getLayer(TRACE_LAYER)) {
        map?.setFilter(TRACE_LAYER, ["in", ["get", "id"], ["literal", filterIds]]);
      }
    } catch (error) {
      console.log("filterTraces ~ error:", error);
    }
  };

  const getTracePopup = (feature: Feature) => {
    const trace =
      typeof feature?.properties?.trace == "string"
        ? JSON.parse(feature?.properties?.trace)
        : feature?.properties?.trace;
    return (
      <TraceViewDetailsPopup
        trace={trace}
        terminalId={params.unitId}
        traceTypeName={feature?.properties?.trace_type_name}
        selectedView={traceStore?.selectedView}
        activityTypes={appStore?.activityTypes ? appStore.activityTypes : []}
        traceTypes={traceStore?.traceTypes ? traceStore?.traceTypes : []}
        showCustomView={isCustomTraceView(trace?.type, traceStore)}
      />
    );
  };

  /************** HANDLER METHODS **************/
  const handleMarkerPopupClose = () => {
    highlightTraces([]);
    handleOnTraceSelect?.(null);
  };

  const handleSelectedTrace = (selectedTrace: ITrace) => {
    setTimeout(() => {
      const element = document.getElementsByClassName("map-custom-popup-container");
      if (element.length > 0) return;
      const feature = traceFeatures.find(
        (feature) => feature?.properties?.id?.toString() == selectedTrace?.id?.toString(),
      );
      if (feature) {
        if (feature?.properties) {
          feature.properties.trace_type_name = selectedTrace?.typeName;
        }
        const popupEle = getTracePopup(feature);
        tracesMapService?.openPopup(feature, popupEle, isMobile, handleMarkerPopupClose);
      }
      highlightTraces([selectedTrace?.id?.toString()]);
    }, 10);
  };

  const handleHighlightTraces = (highlightedTraceIds?: string[]) => {
    const highlightIds = selectedTrace ? [selectedTrace.id.toString()] : highlightedTraceIds || [];
    highlightTraces(highlightIds);
  };

  const handleOnTraceLayerClick = (e?: EventData) => {
    const layerId = map?.queryRenderedFeatures(e?.point)[0].layer.id;
    if (layerId === TRACE_LAYER || layerId === TRACE_CUSTOM_ICON_UNCLUSTERED_POINTS) {
      const feature = e?.features?.[0];
      handleOnTraceSelect?.(JSON.parse(feature?.properties?.trace));
      handleSelectedTask?.(null);
    }
  };

  const removePopup = () => {
    handleHighlightTraces([]);
    props.handleOnTraceSelect?.(null);
    tracesMapService?.closePopup();
  };

  const handleOnStyleChange = () => {
    removePopup();
    addLayerIcons();
    tracesMapService?.addSource(TRACE_SOURCE, {
      type: "FeatureCollection",
      features: traceFeatures,
    });
    addLayer();
  };

  /**************  EVENT LISTENERS **************/
  const addLayerEvents = () => {
    map?.on("zoom", removePopup);
    map?.on("style.load", handleOnStyleChange);
    map?.on("mouseenter", TRACE_LAYER, onMouseEnter);
    map?.on("mouseleave", TRACE_LAYER, onMouseLeave);
    map?.on("click", TRACE_LAYER, handleOnTraceLayerClick);
  };

  const removeLayerEvents = () => {
    map?.off("zoom", removePopup);
    map?.off("style.load", handleOnStyleChange);
    map?.off("mouseenter", TRACE_LAYER, onMouseEnter);
    map?.off("mouseleave", TRACE_LAYER, onMouseLeave);
    map?.off("click", TRACE_LAYER, handleOnTraceLayerClick);
  };

  const onComponentMount = () => {
    addLayerIcons();
    addLayerEvents();
  };

  const onComponentCleanup = () => {
    removeLayerEvents();
    setTraceFeatures([]);
    tracesMapService?.closePopup();
    lngLatBounds.current = null;
    map?.getLayer(TRACE_LAYER) && map?.removeLayer(TRACE_LAYER);
    tracesMapService?.updateSource(TRACE_SOURCE, { type: "FeatureCollection", features: [] });
  };

  /**************  LIFE CYCLE METHODS **************/
  // SCENARIO 1. When map is still loading and we get trace data from stream.
  // SCENARIO 2. When map is loaded and we get trace data from stream.
  useEffect(() => {
    try {
      if (lngLatBounds.current === null) {
        lngLatBounds.current = new LngLatBounds();
      }
      if (feature) {
        lngLatBounds.current?.extend([
          feature?.properties?.coordinates?.[0],
          feature?.properties?.coordinates?.[1],
        ]);
        setTraceFeatures((prevFeatures) => [...prevFeatures, feature]);
        if (map) {
          const updatedGeoJSON = {
            type: "FeatureCollection",
            features: traceFeatures,
          } as FeatureCollection;
          tracesMapService?.addSource(TRACE_SOURCE, updatedGeoJSON);
        }
        updateFitBounds?.(lngLatBounds.current);
      }
    } catch (error) {
      console.log("TracesLayer  ~ error:", error);
    }
  }, [map, feature]);

  useEffect(() => {
    onComponentMount();
  }, [map]);

  useEffect(() => {
    if (map && isDataLoaded) {
      addLayer();
    }
  }, [map, isDataLoaded]);

  useEffect(() => {
    selectedTrace && handleSelectedTrace(selectedTrace);
  }, [selectedTrace]);

  useEffect(() => {
    handleHighlightTraces(highlightedTraceIds);
  }, [highlightedTraceIds]);

  useEffect(() => {
    tracesMapService?.closePopup();
    filteredTraceIds && filterTraces(filteredTraceIds);
  }, [filteredTraceIds]);

  useEffect(() => {
    resetLayer && onComponentCleanup();
  }, [resetLayer]);

  return <></>;
};

export default TracesLayer;
