import React, { Dispatch, SetStateAction, useCallback, useState } from "react";

type SetValue<T> = Dispatch<SetStateAction<T>>;

export function useLocalStorage<T>(key: string, initialValue: T): [T, SetValue<T>] {
  const getValue = useCallback(() => {
    try {
      const data = window.localStorage.getItem(key);
      return data ? JSON.parse(data) : initialValue;
    } catch (error) {
      console.log(`Error reading ${key} from localstorage ${error}`);
    }
  }, [initialValue, key]);

  const [storedValue, setStoredValue] = useState<T>(getValue());

  const setValue = (value: any) => {
    try {
      window.localStorage.setItem(key, JSON.stringify(value));
      setStoredValue(value);
    } catch (error) {
      console.log(`Error setting ${key} to the localstorage ${error}`);
    }
  };

  return [storedValue, setValue];
}
