import React from "react";
import "./Switch.scss";
import Form from "@trimbleinc/modus-react-bootstrap/esm/Form";

export interface ISwitch {
  defaultChecked?: boolean;
  isChecked?: boolean;
  label: string;
  title?: string;
  isEnabled?: boolean;
  onChange?: (flag: boolean) => void;
}

const Switch = (props: ISwitch) => {
  const { label, title, isChecked, defaultChecked, isEnabled, onChange } = props;
  const handleOnChange = () => {
    onChange?.(!isChecked);
  };

  return (
    <Form.Check
      checked={isChecked}
      className="switch-container"
      type="switch"
      id={`custom-switch-${label}`}
      name={label}
      label={<span className="label truncate-text">{label}</span>}
      title={title || label}
      custom
      defaultChecked={defaultChecked}
      disabled={!isEnabled}
      onChange={handleOnChange}
    />
  );
};

export default Switch;
