import React from "react";
import Preloader from "@ttl/shared-react-library/src/components/Preloader/Preloader";
import refreshIcon from "../../../../../assets/images/refresh.svg";
import "./TraceProperties.scss";
import { observer } from "mobx-react-lite";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import TracePropertyDetails from "../TracePropertyDetails/TracePropertyDetails";
import { useTraceDetails } from "../../../../../hooks/domain/useTraceDetails/useTraceDetails";

export interface ITraceProperties {
  traceId: string;
  terminalId?: string;
}
const TraceProperties = observer((props: ITraceProperties) => {
  const { fetchTraceDetails, traceDetails, loading, error } = useTraceDetails({ terminalId: props.terminalId, traceId: props.traceId });

  return (
    <div className="trace-property">
      {loading && (
        <div className="trace-property-loader">
          <Preloader />
        </div>
      )}
      {!loading &&
        !error &&
        traceDetails?.traceProperties &&
        traceDetails?.traceProperties?.length > 0 && (
          <TracePropertyDetails
            traceProperties={traceDetails?.traceProperties}
            terminalId={props?.terminalId}
          />
        )}
      {error && !loading && (
        <div className="trace-property-error">
          <span>{i18nInstance.t("TTM.followup.error.dataLoad")}</span>
          <img
            className="refresh-icon"
            title={i18nInstance.t("TTM.followup.refreshData")}
            src={refreshIcon}
            onClick={fetchTraceDetails}
          />
        </div>
      )}
    </div>
  );
});

export default TraceProperties;
