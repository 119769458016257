import React from "react";
import { ITimelineSegmentData, ITrace, ITraceType } from "../models/TraceModel";
import { coordinateToDegree } from "./utils";
import { IActivityType } from "../models/VehicleModel";
import {
  ACTIVITY_CATEGORY,
  ACTIVITY_COLORS,
  COLOR_VALUES,
  STATE,
  DRIVING_TIMES_CATEGORY,
  DRIVING_TYPES_COLORS,
  HISTORY_VIEWS,
  DRIVING_TYPES,
} from "./constants";
import {
  Feature,
  FeatureCollection,
  GeoJsonProperties,
  Geometry,
} from "@trimblemaps/trimblemaps-js/geojson";
import { Dictionary, groupBy } from "lodash";
import { ITimelineSegment } from "@ttl/shared-react-library/src/packages/timeline/models/TimelineModel";
import { TraceStore } from "../stores/traceStore/trace.store";
import i18nInstance from "@ttl/shared-react-library/src/i18n";

export const getUpdateGeoJSON = (features: Feature[], updateProperties?: any) => {
  return {
    type: "FeatureCollection",
    features: features
      .map((feature: Feature) => {
        const updatedProperties = updateProperties(feature);
        if (!updatedProperties) {
          return null;
        }
        return {
          ...feature,
          properties: {
            ...feature.properties,
            ...updatedProperties,
          },
        };
      })
      .filter(Boolean),
  };
};
export const getUpdatedActivityGeoJSON = (
  features: Feature[],
  activityTypes: IActivityType[],
  activityJSON: any,
) => {
  const updatedGeoJSON = getUpdateGeoJSON(features, (feature: Feature) => {
    const activity = activityTypes.find(
      (activity: IActivityType) => activity.id == feature?.properties?.trace?.eventTypeId,
    );

    if (feature && feature.properties && feature.properties.trace) {
      //checking here if activity type is driving. If yes, weightage is set to 1 else set based on the activity JSON.
      const weightage =
        activity?.code === "DR" ? 1 : activityJSON?.[feature.properties.trace.type]?.weightage ?? 0;
      if (weightage !== 3) {
        // Returning null here to avoid adding the feature with weightage < 3 to the result
        return null;
      }
      const customIcon =
        activity &&
        weightage === 3 &&
        ACTIVITY_CATEGORY[activity?.activityCategory as keyof typeof ACTIVITY_CATEGORY];
      const activityIcon =
        ACTIVITY_COLORS[activity?.icon as keyof typeof ACTIVITY_COLORS] || COLOR_VALUES.GRAY_450;
      return {
        weightage,
        trace_type_name: activity?.name || customIcon,
        custom_icon: customIcon,
        activity_icon: activityIcon,
        icon_name: `${customIcon}_${activityIcon}`,
      };
    }
  });
  return updatedGeoJSON as FeatureCollection<Geometry, GeoJsonProperties>;
};

export const getCoordinates = (coordinate: { lon: number; lat: number }) => {
  const lon = coordinateToDegree(coordinate.lon);
  const lat = coordinateToDegree(coordinate.lat);
  return { lon, lat };
};

export const getUpdatedDrivingTimesGeoJSON = (
  features: Feature[],
  traceTypes: ITraceType[],
  drivingTimesJSON: any,
) => {
  const updatedGeoJSON = getUpdateGeoJSON(features, (feature: Feature) => {
    let traceTypeName = "";
    let customIcon = "";
    let activityIcon = "";

    if (feature?.properties?.trace) {
      const { type, eventTypeId } = feature.properties.trace;
      const weightage = drivingTimesJSON?.[type]?.weightage ?? 0;

      if (weightage === 3) {
        if (DRIVING_TIMES_CATEGORY.hasOwnProperty(type)) {
          const traceType = type as keyof typeof DRIVING_TIMES_CATEGORY;
          customIcon = DRIVING_TIMES_CATEGORY[traceType].icon;
          activityIcon = traceType === 44 ? COLOR_VALUES.BLUE_300 : COLOR_VALUES.YELLOW_100;
          traceTypeName = traceTypes?.find((trace) => trace.traceType === type)?.name || customIcon;
        } else if (type === 82) {
          customIcon = eventTypeId.toLowerCase();
          if (customIcon === DRIVING_TYPES.DRIVING.toLowerCase()) {
            return null;
          }
          traceTypeName = i18nInstance.t(`TTM.followup.tachoStatus.${customIcon}`);
          activityIcon =
            DRIVING_TYPES_COLORS[eventTypeId as keyof typeof DRIVING_TYPES_COLORS] ??
            COLOR_VALUES.GRAY_450;
        }
      }

      return {
        weightage,
        trace_type_name: traceTypeName,
        custom_icon: customIcon,
        activity_icon: activityIcon,
        icon_name: `${customIcon}_${activityIcon}`,
      };
    }
  });
  return updatedGeoJSON as FeatureCollection<Geometry, GeoJsonProperties>;
};

export const getUpdatedTracesLayerGeoJSON = (features: Feature[], jsonData: any) => {
  const updatedGeoJSON = getUpdateGeoJSON(features, (feature: Feature) => {
    if (feature && feature.properties && feature.properties.trace) {
      const weightage = jsonData?.[feature.properties.trace.type]?.weightage ?? 0;
      return {
        weightage,
      };
    }
  });
  return updatedGeoJSON as FeatureCollection<Geometry, GeoJsonProperties>;
};

export const getTraceFeature = (trace: ITrace) => {
  try {
    if (trace?.coordinate && trace.coordinate?.lon && trace.coordinate.lat) {
      const { lon, lat } = getCoordinates(trace.coordinate);
      return {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [lon, lat],
        },
        properties: {
          id: trace?.id?.toString(),
          trace: trace,
          icon: "trace-marker",
          heading: trace?.heading,
          coordinates: [lon, lat],
          timestamp: trace?.timestamp,
        },
      } as Feature;
    }
  } catch (error) {
    console.log("TraceMapService ~ getTraceFeature ~ error:", error);
  }
};

export const getTraceIdsByWeightage = (
  tracesList: ITrace[],
  jsonData: any,
  comparison: (weightage: number) => boolean,
) => {
  return tracesList
    .filter((trace) => comparison(jsonData?.[trace.type]?.weightage))
    .map((trace) => trace.id.toString());
};

export const getTaskFeature = (task: ITimelineSegmentData) => {
  try {
    if (task?.coordinate && task.coordinate?.lon && task.coordinate.lat) {
      const { lon, lat } = getCoordinates(task.coordinate);
      const taskStatus =
        task?.status == 5 || task?.status == 7
          ? STATE[task?.status].toString().toLowerCase()
          : "other";
      const taskOrder = task?.order;
      return {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [lon, lat],
        },
        properties: {
          id: task?.id,
          task: task,
          coordinates: [lon, lat],
          custom_icon: `${taskStatus}-${taskOrder}`,
          iconName: taskStatus,
          order: taskOrder,
        },
      } as Feature;
    }
  } catch (error) {
    console.log("TraceMapService ~ getTaskFeature ~ error:", error);
  }
};

export const processSegments = (segments: ITimelineSegment[]) => {
  const segmentsWithTrip = segments.filter((segment: ITimelineSegmentData) => segment?.trip?.id);

  const segmentsWithoutTrip = segments
    .filter((segment: ITimelineSegmentData) => !segment?.trip?.id)
    .map((segment: ITimelineSegmentData, index: number) => ({
      ...segment,
      order: `${index + 1}`,
    }));

  const groupedSegments = groupBy(segmentsWithTrip, (segment: ITimelineSegmentData) => {
    const tripId = segment?.trip?.id;
    return tripId || "";
  }) as Dictionary<ITimelineSegmentData[]>;

  return {
    segmentsWithoutTrip,
    groupedSegments,
  };
};

export const createImageFromSvg = (svgText: string, resolve: any) => {
  const blob = new Blob([svgText], { type: "image/svg+xml" });
  const url = URL.createObjectURL(blob);
  const img = new Image();
  img.onload = () => resolve(img);
  img.src = url;
};

export const modifyTraceSvgIcon = (svgText: string, color: string, resolve: any) => {
  const modifiedSvg = svgText
    .replace(/fill="[current]*"/g, `fill="${color}"`)
    .replace(/stroke="[current]*"/g, `stroke="${color}"`);
  createImageFromSvg(modifiedSvg, resolve);
};

export const modifyTaskSvgIcon = (svgText: string, order: number, resolve: any) => {
  const modifiedSvg = svgText.replace(
    /<tspan[^>]*>(.*?)<\/tspan>/,
    `<tspan x="10" y="14.5">${order}</tspan>`,
  );
  createImageFromSvg(modifiedSvg, resolve);
};

export const modifyCustomSvgIcon = (svgText: string, color: string) => {
  const modifiedSvg = svgText.replace(
    /(<path[^>]*?)fill="#fff"|fill="current"|stroke="current"/g,
    (match, pathPrefix) => {
      if (pathPrefix) return `${pathPrefix}fill="${color}"`;
      if (match.startsWith('fill="')) return `fill="#fff"`;
      if (match.startsWith('stroke="')) return `stroke="#fff"`;
      return match;
    },
  );
  const blob = new Blob([modifiedSvg], { type: "image/svg+xml" });
  return URL.createObjectURL(blob);
};

export const isHighPriorityWeightage = (
  selectedView: string,
  traceType: number,
  traceStore: TraceStore | null,
) => {
  return traceStore?.traceViewJsonData.get(selectedView)?.[traceType]?.weightage == 3;
};

export const isCustomTraceView = (traceType: number, traceStore: TraceStore | null) => {
  switch (traceStore?.selectedView) {
    case HISTORY_VIEWS.ACTIVITY:
      return isHighPriorityWeightage(HISTORY_VIEWS.ACTIVITY, traceType, traceStore);
    case HISTORY_VIEWS.DRIVING_TIMES:
      return isHighPriorityWeightage(HISTORY_VIEWS.DRIVING_TIMES, traceType, traceStore);
    default:
      return false;
  }
};

export const getTraceTypeName = (
  selectedView: string | undefined,
  trace: any,
  custom_icon: string,
  activityTypes: IActivityType[],
  traceTypes: ITraceType[],
): string => {
  switch (selectedView) {
    case HISTORY_VIEWS.ACTIVITY:
      return (
        activityTypes?.find((activityType) => activityType.id === trace?.eventTypeId)?.name ||
        custom_icon
      );

    case HISTORY_VIEWS.DRIVING_TIMES:
      return trace?.type === 82
        ? i18nInstance.t(`TTM.followup.tachoStatus.${custom_icon.toLowerCase()}`)
        : traceTypes?.find((type) => type.traceType === trace?.type)?.name || custom_icon;

    default:
      return custom_icon;
  }
};
