import React from "react";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import "./IconLabelCard.scss";
import useMobileDetect from "../../../../hooks/useMobileDetect/useMobileDetect";
import { IIconLabelCardOption } from "../../../../common/models";

export interface IIconLabelCard {
  options: IIconLabelCardOption[];
  handleClick: (id: string) => void;
  active?: string;
}
const IconLabelCard = (props: IIconLabelCard) => {
  const { options, handleClick, active } = props;

  const isMobile = useMobileDetect();

  return (
    <div className="icon-label-menu">
      {options.length > 0 &&
        options.map((option: IIconLabelCardOption) => (
          <div
            className={`icon-label-option ${active === option.id ? "active" : ""}`}
            key={option.id}
            onClick={() => handleClick(option.id)}
            title={i18nInstance.t(option.key)}
          >
            <img src={option.img} alt={i18nInstance.t(option.key)} className="mt-1 item-icon" />
            <span className={`item-label ${!isMobile ? "truncate-text" : ""}`}>
              {i18nInstance.t(option.key)}
            </span>
          </div>
        ))}
    </div>
  );
};

export default IconLabelCard;
