import React, { useContext, useEffect, useRef, useState } from "react";
import MapComponent from "../../../../global/organisms/MapComponent/MapComponent";
import TrimbleMaps, { LngLatBounds } from "@trimblemaps/trimblemaps-js";
import { getAppConfig } from "../../../../../common/utils";
import AuthContext from "@ttl/shared-react-library/src/auth/AuthContext";
import useMobileDetect from "../../../../../hooks/useMobileDetect/useMobileDetect";
import { useOutletContext } from "react-router-dom";
import { ITrace, ITraceOutletContext } from "../../../../../models/TraceModel";
import "./TracesMap.scss";
import { observer } from "mobx-react-lite";
import TracesLayer, { TRACE_SOURCE } from "../TracesLayer/TracesLayer";
import TracesViewSelectContainer from "../../../molecules/TracesViewSelectContainer/TracesViewSelectContainer";
import TracesCustomIconLayer from "../TracesCustomIconLayer/TracesCustomIconLayer";
import TaskLayerWrapper from "../TaskLayerWrapper/TaskLayerWrapper";
import { TracesMapService } from "../../../../organisms/MapView/Services/TracesMapService/TracesMapService";
import {
  Feature,
  FeatureCollection,
  GeoJsonProperties,
  Geometry,
} from "@trimblemaps/trimblemaps-js/geojson";
import {
  getTraceFeature,
  getUpdatedActivityGeoJSON,
  getUpdatedTracesLayerGeoJSON,
  getTraceIdsByWeightage,
  getUpdatedDrivingTimesGeoJSON,
} from "../../../../../common/tracesUtils";
import { useTraceStore } from "../../../../../contexts/traces.context";
import { HISTORY_VIEWS } from "../../../../../common/constants";
import TraceService from "../../../../../services/Trace.service";
import { useAppStore } from "../../../../../contexts/app.context";

export interface ITracesMapProps {
  onMapLoad?: () => void;
}

const TracesMap = (props: ITracesMapProps) => {
  const appConfig = getAppConfig();
  const isMobile = useMobileDetect();
  const traceStore = useTraceStore();
  const appStore = useAppStore();
  const { tokens } = useContext(AuthContext);
  const mapContext = useOutletContext<ITraceOutletContext>();
  const [map, setMap] = useState<TrimbleMaps.Map>();
  const [traceFeatures, setTraceFeatures] = useState<Feature>();
  const [tracesMapService, setTracesMapService] = useState<TracesMapService>();
  const [selectedViewGeoJSON, setSelectedViewGeoJSON] =
    useState<FeatureCollection<Geometry, GeoJsonProperties>>();

  const traceService = new TraceService();
  //All the time we need to keep track of the features that are added to the map.
  const traceFeaturesRef = React.useRef<Feature[]>([]);
  const lngLatBounds = useRef<LngLatBounds | null>(null);

  /**************  CONFIGURATION **************/
  const mapConfig = {
    ...appConfig.map,
    locale: tokens?.profile
      ?.language as (typeof TrimbleMaps.Common.Language)[keyof typeof TrimbleMaps.Common.Language],
    isMobile: isMobile,
  };

  const handleOnMapLoad = (map: TrimbleMaps.Map) => {
    const mapService = new TracesMapService(map);
    setTracesMapService(mapService);
    setMap(map);
    props.onMapLoad?.();
  };
  const updateFitBounds = (bounds: LngLatBounds) => {
    if (!lngLatBounds.current) {
      lngLatBounds.current = new LngLatBounds(
        new TrimbleMaps.LngLat(bounds.getWest(), bounds.getSouth()),
        new TrimbleMaps.LngLat(bounds.getEast(), bounds.getNorth()),
      );
    } else {
      lngLatBounds.current.extend(bounds);
    }
    if (map) {
      map.fitBounds(lngLatBounds.current, { padding: 150 });
    }
  };

  const handleTraceData = (trace: ITrace) => {
    const feature = getTraceFeature(trace);
    if (feature) {
      setTraceFeatures(feature);
      traceFeaturesRef.current = [...traceFeaturesRef?.current, feature];
    }
  };

  const handleTraceFilter = (jsonData: any) => {
    const filteredTraceIds = getTraceIdsByWeightage(
      mapContext?.tracesListRef,
      jsonData,
      (weightage) => weightage <= 3,
    );
    mapContext?.handleFilterTraces(filteredTraceIds);
  };

  const handleTracesLayer = (jsonData?: any) => {
    const tracesLayerSource = map?.getSource(TRACE_SOURCE) as TrimbleMaps.GeoJSONSource;
    if (jsonData) {
      const filteredTraceIds = getTraceIdsByWeightage(
        mapContext?.tracesListRef,
        jsonData,
        (weightage) => weightage < 3,
      );
      const filteredFeatures = traceFeaturesRef.current.filter((feature) =>
        filteredTraceIds.includes(feature?.properties?.trace?.id?.toString()),
      );
      const updatedGeoJSON = getUpdatedTracesLayerGeoJSON(filteredFeatures, jsonData);
      tracesLayerSource?.setData(updatedGeoJSON);
    } else {
      tracesLayerSource?.setData({
        type: "FeatureCollection",
        features: traceFeaturesRef.current,
      });
    }
  };

  const getJSONData = async (selectedView: string) => {
    if (
      selectedView !== HISTORY_VIEWS.DEFAULT &&
      !traceStore?.traceViewJsonData.has(selectedView)
    ) {
      const data = (await traceService.getHistoryViewData(selectedView)).data;
      traceStore?.setTraceViewJSON(selectedView, data);
    }
    return traceStore?.traceViewJsonData?.get(selectedView);
  };

  const handleTraceViewSelect = async (selectedView: string) => {
    try {
      setSelectedViewGeoJSON(undefined);
      const jsonData = await getJSONData(selectedView);
      handleTracesLayer(jsonData);
      handleTraceFilter(jsonData);

      if (selectedView === HISTORY_VIEWS.DEFAULT) {
        setSelectedViewGeoJSON({
          type: "FeatureCollection",
          features: [],
        });
        traceStore?.setSelectedView(HISTORY_VIEWS.DEFAULT);
        return;
      }

      const filteredTraceIds = getTraceIdsByWeightage(
        mapContext?.tracesListRef,
        jsonData,
        (weightage) => weightage === 3,
      );

      if (filteredTraceIds && jsonData && traceFeaturesRef?.current) {
        const filteredFeatures = traceFeaturesRef?.current?.filter((feature: any) =>
          filteredTraceIds.includes(feature?.properties?.trace?.id?.toString()),
        );

        let updatedGeoJSON;
        if (selectedView === HISTORY_VIEWS.ACTIVITY) {
          const activityTypes = appStore?.activityTypes;
          if (activityTypes) {
            updatedGeoJSON = getUpdatedActivityGeoJSON(filteredFeatures, activityTypes, jsonData);
          }
        } else if (selectedView === HISTORY_VIEWS.DRIVING_TIMES) {
          const traceTypes = traceStore?.traceTypes;
          if (traceTypes) {
            updatedGeoJSON = getUpdatedDrivingTimesGeoJSON(filteredFeatures, traceTypes, jsonData);
          }
        }
        setSelectedViewGeoJSON(updatedGeoJSON);
      }
    } catch (error) {
      console.log("handleTraceViewSelect ~ error:", error);
    }
  };

  map?.on("style.load", () => {
    handleTracesLayer(traceStore?.traceViewJsonData?.get(traceStore?.selectedView));
  });

  const removeListeners = () => {
    map?.off("style.load", () => {
      handleTracesLayer(traceStore?.traceViewJsonData?.get(traceStore?.selectedView));
    });
  };

  /**************  LIFE CYCLE METHODS **************/
  useEffect(() => {
    if (mapContext?.trace) {
      handleTraceData(mapContext.trace);
    }
  }, [mapContext?.trace]);

  useEffect(() => {
    if (mapContext?.resetTraces) {
      traceFeaturesRef.current = [];
      mapContext.handleResetTraces();
      removeListeners();
    }
  }, [mapContext?.resetTraces]);

  useEffect(() => {
    if (traceStore?.selectedView) {
      handleTraceViewSelect(traceStore.selectedView);
      tracesMapService?.closePopup();
      if (map && lngLatBounds.current) {
        map?.fitBounds(lngLatBounds.current, { padding: 150 });
      }
    }
  }, [traceStore?.selectedView, mapContext?.isDataLoaded]);

  useEffect(() => {
    if (mapContext?.terminalId && mapContext?.traceFilter?.selectedDate) {
      lngLatBounds.current = null;
    }
  }, [mapContext?.terminalId, mapContext?.traceFilter?.selectedDate]);

  return (
    <>
      <div className="traces-map-container">
        {getAppConfig().showTaskTimeline && (
          <TaskLayerWrapper map={map} updateFitBounds={updateFitBounds} />
        )}
        <TracesViewSelectContainer />
      </div>
      <TracesCustomIconLayer
        map={map}
        tracesMapService={tracesMapService}
        resetLayer={mapContext?.resetTraces || traceStore?.selectedView}
        tracesGeoJSON={selectedViewGeoJSON}
        filteredTraceIds={mapContext?.filteredTraceIds}
        handleOnTraceSelect={mapContext?.handleSelectedTrace}
        handleHighlightedTraces={mapContext?.handleHighlightedTraces}
      />
      <TracesLayer
        map={map}
        feature={traceFeatures}
        tracesMapService={tracesMapService}
        resetLayer={mapContext?.resetTraces}
        isDataLoaded={mapContext?.isDataLoaded}
        selectedTrace={mapContext?.selectedTrace}
        filteredTraceIds={mapContext?.filteredTraceIds}
        highlightedTraceIds={mapContext?.highlightedTraceIds}
        updateFitBounds={updateFitBounds}
        handleOnTraceSelect={mapContext?.handleSelectedTrace}
        handleSelectedTask={mapContext?.handleSelectedTask}
      />
      <div className="traces-map h-100 w-100">
        <MapComponent config={mapConfig} handleMapLoad={handleOnMapLoad} />
      </div>
    </>
  );
};

export default observer(TracesMap);
