import React, { useEffect, useState } from "react";
import { ITrace, ITraceType } from "../../../../models/TraceModel";
import backIcon from "../../../../assets/images/arrow_back.svg";
import "./TraceViewDetailsPopup.scss";
import { find } from "lodash";
import TraceDetails from "../../../domain/Trace/organisms/TraceDetails/TraceDetails";
import { IActivityType } from "../../../../models/VehicleModel";
import { getTraceTypeName, modifyCustomSvgIcon } from "../../../../common/tracesUtils";
import {
  ACTIVITY_CATEGORY,
  ACTIVITY_COLORS,
  COLOR_VALUES,
  DRIVING_TIMES_CATEGORY,
  DRIVING_TYPES_COLORS,
  HISTORY_VIEWS,
} from "../../../../common/constants";
import CustomIcons from "../../../../assets/ts/customIcons";
import CustomTraceView from "../CustomTraceView/CustomTraceView";

export interface ITraceViewDetailsPopupProps {
  trace: ITrace;
  traceTypeName?: string;
  traceTypes?: ITraceType[];
  activityTypes?: IActivityType[];
  terminalId?: string;
  selectedView?: string;
  showCustomView?: boolean;
  showTraceProperties?: boolean;
  onBack?: () => void;
  onClose?: () => void;
}

const TraceViewDetailsPopup = (props: ITraceViewDetailsPopupProps) => {
  const [image, setImage] = useState<string>("");
  const [traceName, setTraceName] = useState<string>("");

  const fetchImage = (src: string, color: string) => {
    fetch(src)
      .then((response) => response.text())
      .then((svgText) => setImage(modifyCustomSvgIcon(svgText, color)));
  };

  const fetchTraceDetails = () => {
    let customIcon = "";
    let color = "";
    if (props?.selectedView === HISTORY_VIEWS.ACTIVITY) {
      const activityType = props?.activityTypes?.find(
        (activityType) => activityType.id === props?.trace?.eventTypeId,
      );
      if (activityType) {
        customIcon =
          ACTIVITY_CATEGORY[activityType?.activityCategory as keyof typeof ACTIVITY_CATEGORY];
        color =
          ACTIVITY_COLORS[activityType?.icon as keyof typeof ACTIVITY_COLORS] ||
          COLOR_VALUES.GRAY_450;
      }
    } else if (props?.selectedView === HISTORY_VIEWS.DRIVING_TIMES) {
      if (DRIVING_TIMES_CATEGORY.hasOwnProperty(props?.trace.type)) {
        const traceType = props?.trace.type as keyof typeof DRIVING_TIMES_CATEGORY;
        customIcon = DRIVING_TIMES_CATEGORY[traceType].icon;
        color = traceType === 44 ? COLOR_VALUES.BLUE_300 : COLOR_VALUES.YELLOW_100;
      } else if (props.trace.type === 82) {
        customIcon = props.trace.eventTypeId?.toLowerCase() || "";
        color = DRIVING_TYPES_COLORS[props?.trace.eventTypeId as keyof typeof DRIVING_TYPES_COLORS];
      }
    }

    if (props?.traceTypeName) {
      setTraceName(props?.traceTypeName);
    } else {
      const traceTypeName = getTraceTypeName(
        props?.selectedView,
        props?.trace,
        customIcon,
        props?.activityTypes || [],
        props?.traceTypes || [],
      );
      setTraceName(traceTypeName);
    }

    if (customIcon) {
      const icon = CustomIcons.find((icon) => icon.name === customIcon);
      if (icon) {
        fetchImage(icon?.src, color || COLOR_VALUES.GRAY_450);
      }
    }
  };

  useEffect(() => {
    fetchTraceDetails();
  }, [props?.trace?.id]);

  return (
    <div className="popup-details-container">
      {props?.onBack && <img src={backIcon} className="back-icon" onClick={props.onBack} />}
      {props?.showCustomView ? (
        <CustomTraceView
          trace={props?.trace}
          terminalId={props?.terminalId}
          traceName={traceName}
          image={image}
          onClose={props?.onClose}
        />
      ) : (
        <TraceDetails
          trace={props?.trace}
          terminalId={props?.terminalId}
          activityName={
            props?.activityTypes?.find(
              (activityType) => activityType.id === props?.trace?.eventTypeId,
            )?.name
          }
          traceType={find(props?.traceTypes, { traceType: props?.trace?.type })?.name}
          showTimestamp={true}
          showTraceProperties={props?.showTraceProperties}
          onClose={props?.onClose}
        />
      )}
    </div>
  );
};

export default TraceViewDetailsPopup;
