import { TRACE_MARKER, TRACE_MARKER_HIGHLIGHT } from "./TracesLayer";

export const getIconImageExpression = () => {
  return [
    "case",
    ["==", ["get", "weightage"], 1], // Default marker for weightage = 1
    TRACE_MARKER,
    TRACE_MARKER, // Fallback value
  ] as TrimbleMaps.Expression;
};

export const getIconRotateExpression = () => {
  return [
    "case",
    ["==", ["get", "weightage"], 3], // If weightage is equal to 3
    0, // Don't rotate the icon
    ["get", "heading"], // Rotate the icon based on heading
  ] as TrimbleMaps.Expression;
};

export const getIconSizeExpression = () => {
  return [
    "case",
    ["==", ["get", "weightage"], 2], // Larger size for weightage = 2
    1.2,
    1, // Fallback value
  ] as TrimbleMaps.Expression;
};

export const getIconHighlightExpression = (highlightIds: string[]) => {
  return highlightIds
    ? ([
        "case",
        ["all", ["in", ["get", "id"], ["literal", highlightIds]], ["!=", ["get", "weightage"], 3]],
        TRACE_MARKER_HIGHLIGHT,
        ["==", ["get", "weightage"], 3], // Different icon for weightage = 3
        ["get", "custom_icon"], // Custom icon for weightage = 3,
        TRACE_MARKER, // Fallback default icon
      ] as TrimbleMaps.Expression)
    : TRACE_MARKER;
};

export const getSymbolSortHighlighttExpression = (highlightIds: string[]) => {
  return highlightIds
    ? ([
        "case",
        ["in", ["get", "id"], ["literal", highlightIds]],
        2, // Higher sort key for highlighted marker

        ["==", ["get", "weightage"], 3], // Different icon for weightage = 3
        3, // Higher sort key for weightage = 3

        1, // Default sort key for other markers
      ] as TrimbleMaps.Expression)
    : 1;
};
