import React, { useEffect, useRef, useState } from "react";
import TrimbleMaps, { EventData, LngLatBounds } from "@trimblemaps/trimblemaps-js";
import { ITimelineDetails, ITimelineSegmentData, ITrace } from "../../../../../models/TraceModel";
import {
  Feature,
  FeatureCollection,
  GeoJsonProperties,
  Geometry,
} from "@trimblemaps/trimblemaps-js/geojson";
import { TracesMapService } from "../../../../organisms/MapView/Services/TracesMapService/TracesMapService";
import {
  getTaskFeature,
  modifyTaskSvgIcon,
  processSegments,
} from "../../../../../common/tracesUtils";
import { useAppStore } from "../../../../../contexts/app.context";
import useMobileDetect from "../../../../../hooks/useMobileDetect/useMobileDetect";
import { observer } from "mobx-react-lite";
import { find, sortBy } from "lodash";
import TaskInfo from "../../../../domain/Task/molecules/TaskInfo/TaskInfo";
export interface ITaskLayerProps {
  map?: TrimbleMaps.Map;
  showTaskView: boolean;
  resetLayer?: boolean;
  taskData: ITimelineDetails | null;
  handleSelectedTask: (task: ITimelineSegmentData | null) => void;
  handleSelectedTrace: (trace: ITrace | null) => void;
  updateFitBounds?: (bounds: LngLatBounds) => void;
}
export const TASK_SOURCE = "task-source";
export const TASK_LAYER = "task-layer";

const TaskLayer = (props: ITaskLayerProps) => {
  const {
    map,
    resetLayer,
    taskData,
    showTaskView,
    handleSelectedTask,
    handleSelectedTrace,
    updateFitBounds,
  } = props;
  const appStore = useAppStore();
  const isMobile = useMobileDetect();
  const lngLatBounds = useRef<LngLatBounds | null>(null);
  const svgRef = useRef<Map<string, string>>(new Map());
  const [tracesMapService, setTracesMapService] = useState<TracesMapService>();
  const taskGeoJSONRef = useRef<FeatureCollection>({ type: "FeatureCollection", features: [] });
  const getTaskPopup = (feature: Feature) => {
    const task =
      typeof feature?.properties?.task == "string"
        ? JSON.parse(feature?.properties?.task)
        : feature?.properties?.task;
    return (
      <TaskInfo
        taskName={task?.name}
        tripName={task?.trip?.name}
        activityName={find(appStore?.activityTypes, { code: task?.typeId })?.name}
        location={task?.location}
        timestamp={task?.startTime}
        order={task?.order}
        status={task?.status}
      />
    );
  };

  /**************  SOURCE AND LAYER METHODS **************/
  const addTaskSource = (geoJSON: FeatureCollection<Geometry, GeoJsonProperties>) => {
    tracesMapService?.addSource(TASK_SOURCE, geoJSON);
  };

  const addTaskLayer = () => {
    try {
      if (!map?.getLayer(TASK_LAYER)) {
        map?.addLayer({
          id: TASK_LAYER,
          type: "symbol",
          source: TASK_SOURCE,
          layout: {
            "icon-image": ["get", "custom_icon"],
            "icon-allow-overlap": true,
            "icon-size": 1,
            "symbol-sort-key": 1,
          },
        });
      }
    } catch (error) {
      console.log("TraceMapService ~ addTaskLayer ~ error:", error);
    }
  };

  /**************  LAYER ICON METHODS **************/
  const fetchIcon = (order: number, svgPath: string) => {
    return new Promise((resolve) => {
      const cachedSvgText = svgRef.current.get(svgPath);
      if (cachedSvgText) {
        modifyTaskSvgIcon(cachedSvgText, order, resolve);
      } else {
        fetch(svgPath)
          .then((response) => response.text())
          .then((svgText) => {
            svgRef.current.set(svgPath, svgText);
            modifyTaskSvgIcon(svgText, order, resolve);
          });
      }
    });
  };
  const loadTaskIcons = (updatedGeoJSON: FeatureCollection<Geometry, GeoJsonProperties>) => {
    updatedGeoJSON.features.forEach((feature) => {
      if (feature?.properties?.custom_icon) {
        fetchIcon(
          feature?.properties?.order,
          `${process.env.PUBLIC_URL}/assets/images/${feature?.properties?.iconName}.svg`,
        ).then((img: any) => {
          if (!map?.hasImage(`${feature?.properties?.custom_icon}`)) {
            map?.addImage(`${feature?.properties?.custom_icon}`, img);
          }
        });
      }
    });
    tracesMapService?.updateSource(TASK_SOURCE, updatedGeoJSON);
  };

  /**************  HANDLER METHODS **************/
  const updateTaskData = (task: ITimelineSegmentData) => {
    const feature = getTaskFeature(task);
    if (lngLatBounds.current === null) {
      lngLatBounds.current = new LngLatBounds();
    }
    addTaskSource(taskGeoJSONRef.current);
    if (feature) {
      lngLatBounds.current?.extend([
        feature?.properties?.coordinates?.[0],
        feature?.properties?.coordinates?.[1],
      ]);
      const updatedGeoJSON = {
        type: "FeatureCollection",
        features: [...taskGeoJSONRef.current.features, feature],
      } as FeatureCollection;
      taskGeoJSONRef.current = updatedGeoJSON;
      loadTaskIcons(taskGeoJSONRef.current);
    }
    updateFitBounds?.(lngLatBounds.current);
  };

  const handleTaskData = (task: ITimelineDetails) => {
    task?.timelines?.forEach((timeline) => {
      if (timeline.segments && timeline.segments.length > 0) {
        const segments = sortBy(timeline.segments, "startTime");
        const { segmentsWithoutTrip, groupedSegments } = processSegments(segments);
        const tripSegments = Object.keys(groupedSegments).flatMap((tripId) => {
          if (tripId) {
            return groupedSegments[tripId].map((segment, index) => ({
              ...(segment as ITimelineSegmentData),
              order: `${index + 1}`,
            }));
          }
          return [];
        });
        const updatedSegments = [...segmentsWithoutTrip, ...tripSegments] as ITimelineSegmentData[];
        updatedSegments.map((item) => {
          updateTaskData(item);
        });
      }
    });
  };
  const handleMarkerPopupClose = () => {
    handleSelectedTask(null);
  };

  const handleOnTaskLayerClick = (e?: EventData) => {
    const feature = e?.features[0];
    if (feature) {
      handleSelectedTrace(null);
      handleSelectedTask(JSON.parse(feature?.properties?.task));
      const popupEle = getTaskPopup(feature);
      tracesMapService?.openPopup(feature, popupEle, isMobile, handleMarkerPopupClose);
    }
  };

  const addTaskSourceAndLayer = (mapService: TracesMapService) => {
    addTaskSource(taskGeoJSONRef?.current);
    addTaskLayer();
  };

  const handleOnStyleChange = () => {
    if (taskGeoJSONRef.current?.features.length > 0) {
      loadTaskIcons(taskGeoJSONRef.current);
    }
  };

  const handleOnMapLoad = (map: TrimbleMaps.Map) => {
    const mapService = new TracesMapService(map);
    setTracesMapService(mapService);
    map?.on("style.load", () => {
      addTaskSourceAndLayer(mapService);
      handleOnStyleChange();
    });
    lngLatBounds.current && mapService?.fitBounds(lngLatBounds.current);
  };

  const removeListeners = () => {
    map?.off("style.load", () => {
      addTaskSourceAndLayer;
      handleOnStyleChange;
    });
  };

  useEffect(() => {
    if (map) {
      handleOnMapLoad(map);
      return () => {
        removeListeners();
      };
    }
  }, [map]);
  useEffect(() => {
    tracesMapService?.closePopup();
    if (taskData) {
      handleTaskData(taskData);
    } else {
      lngLatBounds.current = null;
      taskGeoJSONRef.current = { type: "FeatureCollection", features: [] };
      tracesMapService?.updateSource(TASK_SOURCE, taskGeoJSONRef.current);
    }
  }, [taskData]);
  useEffect(() => {
    if (showTaskView && tracesMapService) {
      addTaskSourceAndLayer(tracesMapService);
      map?.on("click", TASK_LAYER, handleOnTaskLayerClick);
    } else {
      lngLatBounds.current = null;
      tracesMapService?.removeLayer(TASK_LAYER);
      tracesMapService?.removeSource(TASK_SOURCE);
    }
  }, [showTaskView]);

  useEffect(() => {
    removeListeners();
  }, [resetLayer]);

  return <></>;
};
export default observer(TaskLayer);
