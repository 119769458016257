import React, { useEffect, useRef } from "react";
import TrimbleMaps from "@trimblemaps/trimblemaps-js";
import { ITrace } from "../../../../../models/TraceModel";
import { TracesMapService } from "../../../../organisms/MapView/Services/TracesMapService/TracesMapService";
import {
  FeatureCollection,
  Geometry,
  GeoJsonProperties,
} from "@trimblemaps/trimblemaps-js/geojson";
import ReactDOM from "react-dom";
import MapCustomPopup from "../../../../organisms/MapView/MapCustomPopup/MapCustomPopup";
import { modifyTraceSvgIcon } from "../../../../../common/tracesUtils";
import { useAppStore } from "../../../../../contexts/app.context";
import { useTraceStore } from "../../../../../contexts/traces.context";
import CustomIcons from "../../../../../assets/ts/customIcons";

export interface ITracesCustomIconLayerProps {
  map?: TrimbleMaps.Map;
  resetLayer?: string | boolean;
  tracesGeoJSON?: FeatureCollection<Geometry, GeoJsonProperties>;
  filteredTraceIds?: string[];
  tracesMapService?: TracesMapService;
  handleOnTraceSelect?: (trace: ITrace | null) => void;
  handleHighlightedTraces?: (highlightIds: string[]) => void;
}

export const TRACE_CUSTOM_ICON_SOURCE = "trace-custom-icon-source";
export const TRACE_CUSTOM_ICON_LAYER = "trace-custom-icon-layer";
export const TRACE_CUSTOM_ICON_LAYER_COUNT = "trace-custom-icon-layer-count";
export const TRACE_CUSTOM_ICON_UNCLUSTERED_POINTS = "trace-custom-icon-unclustered-points";

const TracesCustomIconLayer = (props: ITracesCustomIconLayerProps) => {
  const appStore = useAppStore();
  const traceStore = useTraceStore();

  const {
    map,
    resetLayer,
    tracesGeoJSON,
    filteredTraceIds,
    tracesMapService,
    handleOnTraceSelect,
  } = props;
  const svgPathRf = useRef<Map<string, string>>(new Map());
  const [updatedGeoJSON, setUpdatedGeoJSON] =
    React.useState<FeatureCollection<Geometry, GeoJsonProperties>>();
  const popupRef = useRef<TrimbleMaps.Popup | null>(null);

  /************** EVENT HANDLERS **************/
  const removePopup = () => {
    props?.handleHighlightedTraces?.([]);
    props?.handleOnTraceSelect?.(null);
    if (popupRef.current) {
      popupRef.current.remove();
      popupRef.current = null;
    }
  };

  const handleClusterClick = (event: any) => {
    try {
      if (map && event) {
        handleOnTraceSelect?.(null);
        const features = map.queryRenderedFeatures(event.point, {
          layers: [TRACE_CUSTOM_ICON_LAYER],
        });
        if (!features.length) return;
        const clusterId = features[0].properties?.cluster_id;
        const source = map.getSource(TRACE_CUSTOM_ICON_SOURCE) as TrimbleMaps.GeoJSONSource;
        source?.getClusterLeaves(clusterId, 100, 0, (err, leaves) => {
          if (!err) {
            removePopup();
            const popupContainer = document.createElement("div");
            ReactDOM.render(
              // Unable to use store inside MapCustomPopup, so passing the required props
              <MapCustomPopup
                leaves={leaves}
                appStore={appStore}
                traceStore={traceStore}
                handleSelectedTrace={props?.handleOnTraceSelect}
              />,
              popupContainer,
            );
            popupRef.current = new TrimbleMaps.Popup({
              className: "map-custom-icon-popup",
            });
            popupRef?.current
              ?.setLngLat(leaves[0]?.properties?.coordinates)
              .setDOMContent(popupContainer)
              .addTo(map);
            const element = document.getElementsByClassName("trimblemaps-popup-close-button")[0];
            const handleCloseButtonClick = () => {
              removePopup();
              element?.removeEventListener("click", handleCloseButtonClick);
            };
            element?.addEventListener("click", handleCloseButtonClick);
          }
        });
      }
    } catch (error) {
      console.error("Error on handleClusterClick", error);
    }
  };

  const handleOnCustomIconClick = useRef((e: any) => {
    const feature = e?.features?.[0];
    const trace: ITrace = {
      ...JSON.parse(feature?.properties?.trace),
      typeName: feature?.properties?.trace_type_name,
    };
    handleOnTraceSelect?.(trace);
  });

  const handleOnStyleChange = () => {
    removePopup();
    tracesGeoJSON && loadTraceViewIcons(tracesGeoJSON);
  };

  /**************  EVENT LISTENERS **************/
  const addLayerEvents = () => {
    map?.on("zoom", removePopup);
    map?.on("style.load", handleOnStyleChange);
    map?.on("click", TRACE_CUSTOM_ICON_LAYER, handleClusterClick);
    map?.on("click", TRACE_CUSTOM_ICON_UNCLUSTERED_POINTS, handleOnCustomIconClick.current);
  };

  const removeLayerEvents = () => {
    map?.off("zoom", removePopup);
    map?.off("style.load", handleOnStyleChange);
    map?.off("click", TRACE_CUSTOM_ICON_LAYER, handleClusterClick);
    map?.off("click", TRACE_CUSTOM_ICON_UNCLUSTERED_POINTS, handleOnCustomIconClick.current);
  };

  /**************  LAYER METHODS **************/
  function addLayer(updatedGeoJSON: FeatureCollection<Geometry, GeoJsonProperties>) {
    try {
      if (map && !map?.getLayer(TRACE_CUSTOM_ICON_LAYER)) {
        //GeoJSON source with clustering enabled.
        tracesMapService?.addSource(TRACE_CUSTOM_ICON_SOURCE, updatedGeoJSON, {
          cluster: true,
          clusterRadius: 10,
          clusterMaxZoom: 14,
          maxzoom: 23,
        });

        //Layer for the clustered points.
        map.addLayer({
          id: TRACE_CUSTOM_ICON_LAYER,
          type: "circle",
          source: TRACE_CUSTOM_ICON_SOURCE,
          filter: ["has", "point_count"],
          paint: {
            "circle-color": "#217cbb",
            "circle-radius": 15,
          },
        });

        //Layer for the cluster count labels.
        map.addLayer({
          id: TRACE_CUSTOM_ICON_LAYER_COUNT,
          type: "symbol",
          source: TRACE_CUSTOM_ICON_SOURCE,
          filter: ["has", "point_count"],
          layout: {
            "text-field": "{point_count_abbreviated}",
            "text-size": 12,
            "text-allow-overlap": true,
          },
          paint: {
            "text-color": "#f9f9fb",
          },
        });

        //Layer for the unclustered points.
        map.addLayer({
          id: TRACE_CUSTOM_ICON_UNCLUSTERED_POINTS,
          type: "symbol",
          source: TRACE_CUSTOM_ICON_SOURCE,
          filter: ["!", ["has", "point_count"]], // Show only unclustered points
          layout: {
            "icon-image": ["get", "icon_name"],
            "icon-allow-overlap": true,
          },
        });
      }
    } catch (error) {
      console.log("addLayer ~ error:", error);
    }
  }

  const filterTraces = (filterIds: string[]) => {
    try {
      if (filterIds && map && map?.getLayer(TRACE_CUSTOM_ICON_LAYER)) {
        const idsSet = new Set(filterIds); // Create a Set for fast lookup
        const source = map?.getSource(TRACE_CUSTOM_ICON_SOURCE) as TrimbleMaps.GeoJSONSource;
        // Fetch the GeoJSON data from the source
        const filteredData: FeatureCollection<Geometry, GeoJsonProperties> = {
          type: "FeatureCollection",
          features:
            updatedGeoJSON?.features.filter(
              (feature) => feature.properties && idsSet.has(feature.properties.id),
            ) || [],
        };
        // Update the data source with filtered GeoJSON
        source?.setData(filteredData);
        // Re-apply filters to layers
        map.setFilter(TRACE_CUSTOM_ICON_LAYER, ["has", "point_count"]); // Clusters will automatically adjust
        map.setFilter(TRACE_CUSTOM_ICON_LAYER_COUNT, ["has", "point_count"]);
        map.setFilter(TRACE_CUSTOM_ICON_UNCLUSTERED_POINTS, ["!", ["has", "point_count"]]);
      }
    } catch (error) {
      console.log("filterTraces ~ error:", error);
    }
  };

  /************** UTIL METHODS **************/
  const loadSvgWithColor = (svgPath: string, color: string) => {
    return new Promise((resolve) => {
      const cachedSvgText = svgPathRf.current.get(svgPath);
      if (cachedSvgText) {
        modifyTraceSvgIcon(cachedSvgText, color, resolve);
      } else {
        fetch(svgPath)
          .then((response) => response.text())
          .then((svgText) => {
            svgPathRf.current.set(svgPath, svgText);
            modifyTraceSvgIcon(svgText, color, resolve);
          });
      }
    });
  };
  async function loadTraceViewIcons(
    updatedGeoJSON: FeatureCollection<Geometry, GeoJsonProperties>,
  ) {
    const iconPromises = updatedGeoJSON.features.map(async (feature) => {
      const icon = CustomIcons.find((icon) => {
        return icon.name === feature?.properties?.custom_icon;
      });

      if (icon) {
        const img = await loadSvgWithColor(icon.src, feature?.properties?.activity_icon);
        const iconName = feature?.properties?.icon_name;
        if (map && !map.hasImage(iconName)) {
          map.addImage(iconName, img as HTMLImageElement);
        }
      }
    });

    await Promise.all(iconPromises);

    // Add the layer and other dependent logic
    addLayer(updatedGeoJSON);
    setUpdatedGeoJSON(updatedGeoJSON);
    addLayerEvents();
  }

  /************** HANDLER METHODS **************/

  const onComponentCleanup = () => {
    removeLayerEvents();
    removePopup();
    tracesMapService?.removeLayer(TRACE_CUSTOM_ICON_LAYER);
    tracesMapService?.removeLayer(TRACE_CUSTOM_ICON_LAYER_COUNT);
    tracesMapService?.removeLayer(TRACE_CUSTOM_ICON_UNCLUSTERED_POINTS);
    setTimeout(() => {
      tracesMapService?.removeSource(TRACE_CUSTOM_ICON_SOURCE);
    });
  };

  /************** LIFECYCLE METHODS **************/

  useEffect(() => {
    if (tracesGeoJSON && tracesGeoJSON.features.length > 0) {
      loadTraceViewIcons(tracesGeoJSON);
    } else if (tracesGeoJSON && tracesGeoJSON.features.length == 0) {
      onComponentCleanup();
    }
  }, [tracesGeoJSON]);

  useEffect(() => {
    if (filteredTraceIds) {
      removePopup();
      filterTraces(filteredTraceIds);
    }
  }, [filteredTraceIds]);

  useEffect(() => {
    if (resetLayer) {
      onComponentCleanup();
    }
  }, [resetLayer]);

  return <></>;
};

export default TracesCustomIconLayer;
