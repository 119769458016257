import React, { useEffect, useRef, useState } from "react";
import "./TraceTypesSearch.scss";
import MultiSelect, { IMultiSelect } from "../../../../molecules/MultiSelect/MultiSelect";
import { useTraceStore } from "../../../../../contexts/traces.context";
import { observer } from "mobx-react-lite";
import useMobileDetect from "../../../../../hooks/useMobileDetect/useMobileDetect";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import close from "../../../../../assets/images/close.svg";
import { sendMonitoringLogs } from "../../../../../common/utils";
import { isEqual } from "lodash";
import { HISTORY_VIEWS } from "../../../../../common/constants";
import TraceService from "../../../../../services/Trace.service";

interface ITraceTypeSearch {
  onClose?: (val?: any) => void;
}

const TraceTypeSearch = (props: ITraceTypeSearch) => {
  const traceStore = useTraceStore();
  const isMobile = useMobileDetect();
  const traceService = new TraceService();
  const [traceTypeOptions, setTraceTypeOptions] = useState<IMultiSelect[]>([]);
  const [filteredTraceTypes, setFilteredTraceTypes] = useState<IMultiSelect[]>([]);

  const traceTypes = useRef(traceStore?.mappedTraces || []);

  const handleOnApply = () => {
    try {
      if (
        filteredTraceTypes.length > 0 &&
        !isEqual(filteredTraceTypes, traceStore?.traceFilter?.traceTypes)
      ) {
        traceStore?.setTraceFilter({
          ...traceStore.traceFilter,
          traceTypes: filteredTraceTypes,
        });
      }
      isMobile && props.onClose?.();
      sendMonitoringLogs(
        "HISTORY_FILTER_TRACE_TYPES",
        filteredTraceTypes.map((trace) => trace?.label)?.join(","),
      );
    } catch (error) {
      console.log("traceTypeSearch ~ handleOnApply ~ error:", error);
    }
  };

  // In mobile, we don't want to reset the trace types on clear.
  // We clear, only when user deselects the trace types and closes the filter panel.
  const handleOnClear = () => {
    sendMonitoringLogs("HISTORY_FILTER_TRACE_TYPES_CLEAR");
    setFilteredTraceTypes([]);
    !isMobile &&
      traceStore?.setTraceFilter({
        ...traceStore.traceFilter,
        traceTypes: [],
      });
  };

  useEffect(() => {
    if (traceStore?.traceFilter?.traceTypes && traceStore?.traceFilter?.traceTypes?.length > 0) {
      setFilteredTraceTypes(traceStore?.traceFilter?.traceTypes);
    }
  }, []);

  /**
   * Function to fetch the trace types based on the selected view.
   * @param selectedView - selected view from the History select.
   */
  const fetchAndSetTraceTypeOptions = async (selectedView: string) => {
    if (!traceStore?.traceViewJsonData.has(selectedView)) {
      const jsonData = (await traceService.getHistoryViewData(selectedView)).data;
      traceStore?.setTraceViewJSON(selectedView, jsonData);
    }
    const jsonData = traceStore?.traceViewJsonData?.get(selectedView);
    const viewTraceTypes = traceStore?.mappedTraces?.filter((traceType) =>
      Object.keys(jsonData).includes(traceType?.id?.toString() ?? ""),
    );
    const matchingFilteredTraces = viewTraceTypes?.filter((traceType) =>
      filteredTraceTypes.some((trace) => trace.value == traceType.value),
    );
    setTraceTypeOptions(viewTraceTypes || []);
    if (filteredTraceTypes && filteredTraceTypes.length > 0) {
      const updatedFilteredTraceTypes =
        matchingFilteredTraces && matchingFilteredTraces.length > 0 ? matchingFilteredTraces : [];
      setFilteredTraceTypes(updatedFilteredTraceTypes);
      if (!isEqual(matchingFilteredTraces, filteredTraceTypes)) {
        traceStore?.setTraceFilter({
          ...traceStore.traceFilter,
          traceTypes: updatedFilteredTraceTypes,
        });
      }
    }
  };

  const updateTraceOptions = () => {
    traceTypes.current = traceStore?.mappedTraces || [];
    const updateTraceFilter = traceTypes.current.filter((traceType) =>
      filteredTraceTypes.some((filteredTraceType) => filteredTraceType.id === traceType.id),
    );
    setFilteredTraceTypes(updateTraceFilter);
    if (traceStore?.selectedView && traceStore?.selectedView !== HISTORY_VIEWS.DEFAULT) {
      fetchAndSetTraceTypeOptions(traceStore?.selectedView);
    } else {
      setTraceTypeOptions(traceTypes.current);
    }
  };

  /**
   * Function to handle the change in selected view and fetch the trace types based on the selected view.
   */
  const handleSelectedViewChange = async () => {
    try {
      const { selectedView, mappedTraces } = traceStore || {};
      if (!selectedView) return;
      switch (selectedView) {
        case HISTORY_VIEWS.ACTIVITY:
          fetchAndSetTraceTypeOptions(HISTORY_VIEWS.ACTIVITY);
          break;
        case HISTORY_VIEWS.DRIVING_TIMES:
          fetchAndSetTraceTypeOptions(HISTORY_VIEWS.DRIVING_TIMES);
          break;
        case HISTORY_VIEWS.DEFAULT:
          setTraceTypeOptions(mappedTraces || []);
          break;
        default:
          setTraceTypeOptions(mappedTraces || []);
          break;
      }
    } catch (error) {
      console.error("Error in handleSelectedViewChange:", error);
    }
  };

  useEffect(() => {
    if (traceStore?.selectedView) {
      handleSelectedViewChange();
    }
  }, [traceStore?.selectedView]);

  useEffect(() => {
    updateTraceOptions();
  }, [traceStore?.traceTypes]);

  return (
    <div className={`trace-types-multiselect${isMobile ? "-mob" : ""}`}>
      {isMobile && (
        <div className="trace-types-header">
          <span>{i18nInstance.t("TTM.followup.traces.tracesTypeList")}</span>
          <img
            src={close}
            className="close-icon"
            alt={i18nInstance.t("TTM.followup.close")}
            onClick={() => props?.onClose?.(filteredTraceTypes)}
          />
        </div>
      )}
      {traceTypes && traceTypes.current.length > 0 && (
        <div className={`${isMobile && "trace-types-mob"}`}>
          <MultiSelect
            label={""}
            enableSearch
            showApplyButton
            options={traceTypeOptions}
            defaultIsOpen={isMobile}
            value={filteredTraceTypes}
            placeholder={i18nInstance.t("TTM.followup.traces.filter.tracesTypes")}
            onApply={handleOnApply}
            onClear={handleOnClear}
            onChange={setFilteredTraceTypes}
          />
        </div>
      )}
    </div>
  );
};

export default observer(TraceTypeSearch);
