import React, { useEffect, useRef, useState } from "react";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import { sendMonitoringLogs } from "../../../../common/utils";
import Card from "@trimbleinc/modus-react-bootstrap/esm/Card";
import IconLabelCard from "../../../global/molecules/IconLabelCard/IconLabelCard";
import defaultView from "../../../../assets/images/trace/default_view.svg";
import activityView from "../../../../assets/images/trace/activity_view.svg";
import drivingTimesView from "../../../../assets/images/trace/driving_times_view.svg";
import "./TracesViewSelectContainer.scss";
import useOnOutsideClick from "@utilityjs/use-on-outside-click";
import { HISTORY_VIEWS } from "../../../../common/constants";
import { IIconLabelCardOption } from "../../../../common/models";
import { useTraceStore } from "../../../../contexts/traces.context";
import betaImg from "../../../../assets/images/beta.svg";

const TracesViewSelectContainer = () => {
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const traceViewSelectContainerRef = useRef<HTMLDivElement>(null);
  const traceStore = useTraceStore();

  useOnOutsideClick(traceViewSelectContainerRef, () => {
    setShowOptions(false);
  });

  const traceViewOptions: IIconLabelCardOption[] = [
    {
      id: HISTORY_VIEWS.DEFAULT,
      img: defaultView,
      key: "TTM.followup.default",
    },
    {
      id: HISTORY_VIEWS.ACTIVITY,
      img: activityView,
      key: "TTM.followup.activity",
    },
    {
      id: HISTORY_VIEWS.DRIVING_TIMES,
      img: drivingTimesView,
      key: "TTM.followup.drivingTimes",
    },
  ];

  const handleTraceViewSelect = (view: string) => {
    traceStore?.setSelectedView(view);
    setShowOptions(false);
    sendMonitoringLogs(`HISTORY_SELECT_${view?.toUpperCase()}_VIEW`);
  };

  const iconAndLabel = traceViewOptions.find((option) => option.id === traceStore?.selectedView);

  return (
    <div ref={traceViewSelectContainerRef} className="trace-view-container">
      <img
        src={iconAndLabel?.img}
        alt="trace-view-icon"
        title={i18nInstance.t(iconAndLabel?.key || "")}
        className="trace-view-menu-icon"
        onClick={() => {
          setShowOptions(!showOptions);
          sendMonitoringLogs(showOptions ? "HISTORY_VIEW_MENU_OPEN" : "HISTORY_VIEW_MENU_CLOSE");
        }}
      />
      {showOptions && (
        <Card border="dark" className="shadow trace-view-menu-card">
          <img className="beta-img" src={betaImg} />
          <IconLabelCard
            options={traceViewOptions}
            handleClick={handleTraceViewSelect}
            active={traceStore?.selectedView}
          />
        </Card>
      )}
    </div>
  );
};

export default TracesViewSelectContainer;
