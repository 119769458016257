import React from "react";
import {
  getBriefAddress,
  getFormattedAddress,
  getFormattedPlaceDetails,
} from "../../../../../common/utils";
import { IAddress, IPlace } from "../../../../../models/VehicleModel";
import Dash from "../../../../atoms/Dash/Dash";
export interface IAddressProps {
  data: IAddress;
  lineClamp?: number;
  isBrief?: boolean;
  place?: IPlace;
}
const defaultProps: IAddressProps = {
  data: {} as IAddress,
  lineClamp: 2,
  isBrief: false,
  place: {} as IPlace,
};
const Address = (props: IAddressProps) => {
  const { data, lineClamp, isBrief, place } = props;
  const addressVal = isBrief ? getBriefAddress(data) : getFormattedAddress(data);
  const hoverDetails = place ? getFormattedPlaceDetails(place, data) : addressVal;

  return props.data ? (
    <div
      className="d-flex flex-wrap truncate-lines address"
      style={{ WebkitLineClamp: isBrief ? 1 : lineClamp }}
      title={hoverDetails}
    >
      {place?.name ? place.name : addressVal}
    </div>
  ) : (
    <Dash />
  );
};
Address.defaultProps = defaultProps;
export default Address;
