import React from "react";
import "./TaskDetails.scss";
import { STATE } from "../../../common/constants";
import DateTime from "../DateTime/DateTime";
import ContactDetails from "../ContactView/ContactView";
import { ITask } from "../../../models/VehicleDetailsModel";
import i18nInstance from "@ttl/shared-react-library/src/i18n";
import TaskArrivalTimeline from "../TaskArrivalTimeline/TaskArrivalTimeline";
import { find } from "lodash";
import { useAppStore } from "../../../contexts/app.context";
import TaskDescription from "../TaskDescription/TaskDescription";
import TaskInfo from "../../domain/Task/molecules/TaskInfo/TaskInfo";
import TimeLine from "../TimeLineIcon/TimeLineIcon";
export interface ITaskDetails {
  task: ITask;
  order?: number;
  nextTask?: boolean;
  isTaskDescriptionActive?: boolean;
  toggleTaskDescription?: (id: string) => void;
}
const TaskDetails = (props: ITaskDetails) => {
  const { task, order, nextTask, isTaskDescriptionActive, toggleTaskDescription } = props;
  const appStore = useAppStore();
  const isFinished = task.status === STATE.FINISHED ? "accordion-trip-content-completed" : "";
  const activityName = find(appStore?.activityTypes, { code: task?.activityType?.code })?.name;
  return task && task.id ? (
    <div className="accordion-trip-content" id={task.id}>
      <div className="task-details-eta task-arrivalTime">
        {task.arrivalTime && (
          <TaskArrivalTimeline
            status={task.status}
            actual={task?.arrivalTime?.actual}
            scheduled={task?.arrivalTime?.scheduled}
            expected={task?.arrivalTime?.expected}
          />
        )}
      </div>
      <div className={`d-flex ${isFinished}`}>
        {task.status && <TimeLine status={task.status} isNextTask={nextTask} order={order} />}
      </div>
      <div className={`task-details-content ${isFinished}`}>
        <div className="task-details-address-container">
          <div className="task-details-address">
            <TaskInfo
              taskName={task.name}
              activityName={activityName}
              location={task?.location?.address}
            />
          </div>
          {task?.window && (task?.window?.startTime || task?.window?.endTime) ? (
            <div className="time-window">
              <span>{i18nInstance.t("TTM.followup.timeWindow")}</span>
              {task?.window?.startTime ? (
                <div className="start-time">
                  <DateTime dateTime={task?.window?.startTime} />
                </div>
              ) : null}
              {task?.window?.endTime ? (
                <div className="end-time">
                  <DateTime dateTime={task?.window?.endTime} />
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
        {task?.contact && <ContactDetails data={task?.contact} />}
        {task?.description && (
          <TaskDescription
            id={task.id}
            description={task.description}
            isActive={isTaskDescriptionActive}
            toggleTaskDescription={toggleTaskDescription}
          />
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};
export default TaskDetails;
