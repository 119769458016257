import React from "react";
import useMobileDetect from "../../../../../hooks/useMobileDetect/useMobileDetect";
import { ILocation } from "@ttl/shared-react-library/src/packages/timeline/models/TimelineModel";
import TimeLine from "../../../../molecules/TimeLineIcon/TimeLineIcon";
import "./TaskInfo.scss";
import Address from "../../../Vehicle/molecules/Address/Address";
export interface ITaskInfo {
  taskName: string;
  tripName?: string;
  activityName?: string;
  location?: ILocation;
  timestamp?: number;
  order?: number;
  status?: number;
}
const TaskInfo = (props: ITaskInfo) => {
  const { taskName, tripName, activityName, location, timestamp, order, status } = props;
  const isMobile = useMobileDetect();
  const time = timestamp && new Date(timestamp);
  return (
    <div className="task-details-info-container">
      {tripName && <div className="task-details-trip">{tripName}</div>}
      <div className="d-flex">
        {time && (
          <div className="task-details-time mr-1">
            {time.getHours()}:{time.getMinutes()}
          </div>
        )}
        {status && (
          <div className={`d-flex mr-2`}>
            <TimeLine status={status} order={order} />
          </div>
        )}
        <div className="task-details-info">
          <div
            className={`break-word ${!isMobile ? "truncate-lines line-clamp-1" : ""}`}
            style={{ WebkitLineClamp: 1 }}
            title={taskName}
          >
            {taskName}
          </div>
          {activityName && (
            <div
              className={`break-word ${!isMobile ? "truncate-lines line-clamp-1" : ""}`}
              style={{ WebkitLineClamp: 1 }}
              title={activityName}
            >
              {activityName}
            </div>
          )}
          {location && (
            <div className="d-flex flex-wrap">
              <Address data={location} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default TaskInfo;
