import { useState, useEffect } from "react";
import { AxiosResponse } from "axios";
import TraceService from "../../../services/Trace.service";
import { useTraceStore } from "../../../contexts/traces.context";
import { ITraceDetails } from "../../../models/TraceModel";

export interface IUseTraceDetailsProps {
  traceId: string;
  terminalId?: string;
}

export const useTraceDetails = (props: IUseTraceDetailsProps) => {
  const [traceDetails, setTraceDetails] = useState<ITraceDetails>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const traceService = new TraceService();
  const traceStore = useTraceStore();

  const fetchTraceDetails = () => {
    setLoading(true);
    if (props?.terminalId && props?.traceId) {
      traceService
        .getTraceDetails(props.terminalId, props.traceId)
        .then((response: AxiosResponse) => {
          traceStore?.setTraceDetails(response.data);
          setTraceDetails(response.data);
          setLoading(false);
          setError(false);
        })
        .catch((e) => {
          console.error("Error while fetching trace details", e);
          setLoading(false);
          setError(true);
        });
    }
  };

  const loadTraceDetails = () => {
    const details = traceStore?.traceDetails[props?.traceId];
    if (details) {
      setTraceDetails(details);
      setLoading(false);
    } else {
      fetchTraceDetails();
    }
  };

  useEffect(() => {
    loadTraceDetails();
  }, [props?.traceId]);

  return { fetchTraceDetails, traceDetails, loading, error };
}