import { getTraceURL } from "../common/serviceUtils";
import { TIMELINE_CATEGORY } from "../common/timelineUtils";
import { AppService } from "./app.services";

class TraceService {
  getVehicleList() {
    return AppService.axios.get(getTraceURL("terminals"));
  }
  getTraceTypes() {
    return AppService.axios.get(getTraceURL("tracetypes"));
  }
  getTraces(terminalId?: string, startTime?: string, endTime?: string, traceTypes?: string) {
    const traceTypesParam = traceTypes && traceTypes.length > 0 ? `&traceTypes=${traceTypes}` : "";
    return getTraceURL(
      `traces/${terminalId}?startTime=${startTime}&endTime=${endTime}${traceTypesParam}`,
    );
  }
  getTraceDetails(terminalId: string, traceId: string) {
    return AppService.axios.get(getTraceURL(`traces/${terminalId}/${traceId}`));
  }
  getTraceVehiclesList() {
    return getTraceURL("vehicles");
  }
  getTimelineDetails(
    category: TIMELINE_CATEGORY,
    terminalId: string,
    startTime: string,
    endTime: string,
    controller?: AbortController,
  ) {
    return AppService.axios.get(
      getTraceURL(`${category}/${terminalId}?startTime=${startTime}&endTime=${endTime}`),
      {
        signal: controller?.signal,
      },
    );
  }
  getVehicleSummary(
    terminalId: string,
    startTime: string,
    endTime: string,
    controller?: AbortController,
  ) {
    return AppService.axios.get(
      getTraceURL(`vehicleSummary/${terminalId}?startTime=${startTime}&endTime=${endTime}`),
      {
        signal: controller?.signal,
      },
    );
  }
  getHistoryViewData(fileName: string) {
    return AppService.axios.get(
      `${process.env.PUBLIC_URL}/assets/data/history_view/${fileName}.json`,
    );
  }

  getTraceSprite() {
    return AppService.axios.get(`${process.env.PUBLIC_URL}/assets/data/sprite/sprite.json`);
  }
}

export default TraceService;
